function sumDigits(cpf: string, lastDigit: number, lastIndexSequenceCalculation: number): number {
  const countDigitsArray = Array.from(Array(lastDigit), (_, n) => n + 1);
  const reducer = (acc: number, i: number) => {
    return acc + parseInt(cpf.substring(i - 1, i), 10) * (lastIndexSequenceCalculation - i);
  };

  return countDigitsArray.reduce(reducer, 0);
}

function calculateRest(resultSum: number): number {
  const rest = (resultSum * 10) % 11;

  return rest === 10 || rest === 11 ? 0 : rest;
}

function compareRestAndDigit(cpf: string, digit: number, rest: number): boolean {
  if (rest !== parseInt(cpf.substring(digit, digit + 1), 10)) {
    return false;
  }

  return true;
}

export default function validateCPF(cpf: string): boolean {
  if (cpf.length !== 11 || !Array.from(cpf).filter(e => e !== cpf[0]).length) {
    return false;
  }

  const validateReducer = (_: boolean, lastDigit: number) => {
    const sum = sumDigits(cpf, lastDigit, lastDigit + 2);
    const rest = calculateRest(sum);

    if (!compareRestAndDigit(cpf, lastDigit, rest)) {
      return false;
    }

    return true;
  };

  return [9, 10].reduce(validateReducer, false);
}
