import React, { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import useProposal from '../../hooks/useProposal';
import Header from '../../components/atoms/Header';
import Title from '../../components/atoms/Title';
import SelectEntry from '../../components/atoms/SelectEntry';
import TextEntry from '../../components/atoms/TextEntry';
import ButtonFilled from '../../components/atoms/ButtonFilled';
import { Container, Content, ViewButtons, Form } from './styles';

type ProposalTwoFlowPersonalData = {
  peopleRg: string;
  peopleGender: string;
  peopleNameMother: string;
  peopleMaritalStatus: string;
};

const ProposalTwoFlowPersonalData = (): JSX.Element => {
  const { proposal, setProposal } = useProposal();

  const navigate = useNavigate();

  const schema = yup.object({
    peopleRg: yup.string().required('Obrigatório'),
    peopleGender: yup.string().required('Obrigatório'),
    peopleNameMother: yup.string().required('Obrigatório').trim(),
    peopleMaritalStatus: yup.string().trim(),
  });

  const { handleSubmit, control } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: ProposalTwoFlowPersonalData) => {
    const { peopleGender, peopleNameMother, peopleRg, peopleMaritalStatus } = data;
    setProposal({
      ...proposal,
      people_gender: peopleGender,
      people_name_mother: peopleNameMother,
      people_rg: peopleRg,
      people_marital_status: peopleMaritalStatus,
    });
    navigate('/proposal-two-flow-address');
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container data-testid="ProposalTwoFlowPersonalData">
      <Header />
      <Title>
        {proposal?.people_name?.split(' ')[0]}, queremos conhecer mais sobre você! Preencha seus
        dados pessoais
      </Title>
      <Content>
        <div>
          <Form>
            <Controller
              control={control}
              name="peopleRg"
              defaultValue=""
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextEntry
                  error={error?.message}
                  value={value}
                  placeholder="RG"
                  onChange={onChange}
                />
              )}
            />

            <Controller
              control={control}
              name="peopleGender"
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <SelectEntry
                  data={[
                    { id: 1, name: 'Masculino', value: 'MASCULINO' },
                    { id: 2, name: 'Feminino', value: 'FEMININO' },
                  ]}
                  placeholder="Sexo"
                  onChange={onChange}
                  optionsProperties={{
                    key: 'id',
                    name: 'name',
                    value: 'value',
                  }}
                  error={error?.message}
                  value={value}
                />
              )}
            />

            <Controller
              control={control}
              name="peopleMaritalStatus"
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <SelectEntry
                  data={[
                    { id: 1, name: 'SOLTEIRO', value: 'SOLTEIRO' },
                    { id: 2, name: 'CASADO(A)', value: 'CASADO' },
                    { id: 3, name: 'VIUVO(A)', value: 'VIUVO' },
                  ]}
                  placeholder="Estado Civil"
                  onChange={onChange}
                  optionsProperties={{
                    key: 'id',
                    name: 'name',
                    value: 'value',
                  }}
                  error={error?.message}
                  value={value}
                />
              )}
            />

            <Controller
              control={control}
              name="peopleNameMother"
              defaultValue=""
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextEntry
                  error={error?.message}
                  value={value}
                  placeholder="Nome da mãe"
                  onChange={onChange}
                />
              )}
            />
          </Form>

          <ViewButtons>
            <ButtonFilled onClick={handleSubmit<ProposalTwoFlowPersonalData>(onSubmit)}>
              Avançar
            </ButtonFilled>
          </ViewButtons>
        </div>
      </Content>
    </Container>
  );
};

export default ProposalTwoFlowPersonalData;
