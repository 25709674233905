import styled from 'styled-components';

export const Container = styled.div`
  background-color: #000;
  padding: 15px;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 999;
`;

export const ImagePreview = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;

export const Image = styled.img`
  width: 100%;
`;

export const GroupButtons = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  button {
    margin: 0 5px;
    width: 50% important;
  }
`;

export const BoxFixed = styled.div`
  position: absolute;
  bottom: 20px;
  right: 20px;
  left: 20px;

  p {
    color: #fff;
    padding: 30px 0;
    text-align: center;
  }
`;
export const ErrorConnectContainer = styled.div`
  width: 100vw;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;
`;
