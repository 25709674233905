import React from 'react';
import { useTheme } from 'styled-components';
import { LoadingOutlined } from '@ant-design/icons';
import { ButtonCustom } from './styles';
import { ButtonProps } from '../../../models/Button';

export default function ButtonLink({
  width,
  height,
  loading,
  disabled,
  children,
  ...rest
}: ButtonProps): JSX.Element {
  const { colors } = useTheme();
  return (
    <ButtonCustom width={width} height={height} disabled={disabled} {...rest}>
      {loading ? (
        <LoadingOutlined data-testid="Loading" style={{ fontSize: 25, color: colors.primary }} />
      ) : (
        children
      )}
    </ButtonCustom>
  );
}
