import styled from 'styled-components';

type Props = {
  height?: number;
};

export const Item = styled.div<Props>`
  width: 100%;
  border: none;
  outline: 0;
  background-color: #f7f7f7;
  border-radius: 8px;
  height: ${props => props.height || 63}px;
  list-style-type: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 22px;
  transition: opacity 0.2s ease-out;

  p {
    font-weight: bold;
    color: ${props => props.theme.colors.gray200};
  }

  &:active {
    opacity: 0.7;
  }
`;
