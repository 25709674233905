import React, { useCallback, useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';

import useDialog from '../../hooks/useDialog';
import useProposal from '../../hooks/useProposal';
import Header from '../../components/atoms/Header';
import Title from '../../components/atoms/Title';
import NumberEntry from '../../components/atoms/NumberEntry';
import ButtonFilled from '../../components/atoms/ButtonFilled';
import SelectEntry from '../../components/atoms/SelectEntry';
import ErrorConnect from '../../components/molecules/ErrorConnect';
import FixedToBottom from '../../components/molecules/FixedToBottom';
import { Container, Content, Form, ErrorConnectContainer } from './styles';
import GetOccupationsService from '../../services/GetOccupationsService';
import GetCyclesService from '../../services/GetCyclesService';
import { regexStringNotStartedInZero } from '../../utils/regex';
import { ICycle } from '../../models/Cycle';
import { IOccupation } from '../../models/Occupation';
import errorHandling from '../../utils/errorHandling';

type ErrorConnectData = {
  isError: boolean;
  callbacks: (() => Promise<void>)[];
};

type FormData = {
  infoComOccupationId: string;
  infoComTimeEmployee: string;
  cycleId: string;
};

const schema = yup.object({
  infoComOccupationId: yup.string().required('Obrigatório'),
  infoComTimeEmployee: yup.string().required('Obrigatório').matches(regexStringNotStartedInZero, {
    message: 'Informe um número a partir de 1',
    excludeEmptyString: true,
  }),
  cycleId: yup.string().required('Obrigatório'),
});

export default function PersonalTwoFlowProfessionalData(): JSX.Element {
  const [buttonLoading, setButtonLoading] = useState(false);
  const [selectOccupationsLoading, setSelectOccupationsLoading] = useState(false);
  const [selectCyclesLoading, setSelectCyclesLoading] = useState(false);
  const [errorConnect, setErrorConnect] = useState<ErrorConnectData>({
    isError: false,
    callbacks: [],
  });
  const [occupations, setOccupations] = useState<IOccupation[]>([]);
  const [cycles, setCycles] = useState<ICycle[]>([]);

  const { setProposal, proposal } = useProposal();
  const { openDialog } = useDialog();

  const { handleSubmit, control } = useForm({
    resolver: yupResolver(schema),
  });
  const navigate = useNavigate();

  const onSubmit = ({ cycleId, infoComOccupationId, infoComTimeEmployee }: FormData) => {
    setProposal({
      ...proposal,
      cycle_id: cycleId,
      info_com_time_employee: Number(infoComTimeEmployee),
      info_com_occupation_id: infoComOccupationId,
    });

    navigate('/tutorial-camera');
  };

  const handlingHttpErrors = useCallback(
    (error: any, fn: () => Promise<void>) => {
      const { type, message } = errorHandling(error);

      if (type === 'ErrorConnect') {
        setErrorConnect(({ callbacks }) => ({
          isError: true,
          callbacks: callbacks.length === 2 || callbacks.includes(fn) ? [fn] : [...callbacks, fn],
        }));
        return;
      }

      setErrorConnect({
        isError: false,
        callbacks: [],
      });
      openDialog({
        title: 'Ocorreu um erro no envio das informações',
        message,
        type: 'danger',
      });
    },
    [openDialog],
  );

  function updateErrorConnectStateOnTaskSuccess(fn: () => Promise<void>) {
    setErrorConnect(({ callbacks, isError }) => {
      if (callbacks.length === 1) {
        return {
          isError: false,
          callbacks: [],
        };
      }

      return {
        isError,
        callbacks: callbacks.filter(callback => callback !== fn),
      };
    });
  }

  const getOccupations = useCallback(async () => {
    try {
      setSelectOccupationsLoading(true);
      setButtonLoading(true);

      const response = await GetOccupationsService.execute();

      setOccupations(response);
      updateErrorConnectStateOnTaskSuccess(getOccupations);
    } catch (error: any) {
      handlingHttpErrors(error, getOccupations);
    } finally {
      setSelectOccupationsLoading(false);
      setButtonLoading(false);
    }
  }, [handlingHttpErrors]);

  const getCycles = useCallback(async () => {
    try {
      setSelectCyclesLoading(true);
      setButtonLoading(true);

      const response = await GetCyclesService.execute();

      setCycles(response);
      updateErrorConnectStateOnTaskSuccess(getCycles);
    } catch (error: any) {
      handlingHttpErrors(error, getCycles);
    } finally {
      setSelectCyclesLoading(false);
      setButtonLoading(false);
    }
  }, [handlingHttpErrors]);

  useEffect(() => {
    getOccupations();
    getCycles();
  }, [getOccupations, getCycles]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container>
      <Header />

      {!errorConnect.isError ? (
        <>
          <Content>
            <Title>Preencha os dados profissionais</Title>

            <Form>
              <Controller
                control={control}
                name="infoComOccupationId"
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <SelectEntry
                    loading={selectOccupationsLoading}
                    error={error?.message}
                    onChange={onChange}
                    value={value}
                    placeholder="Ocupação"
                    data={occupations}
                    optionsProperties={{
                      key: 'id',
                      name: 'name',
                      value: 'id',
                    }}
                  />
                )}
              />

              <Controller
                name="infoComTimeEmployee"
                defaultValue=""
                control={control}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <NumberEntry
                    error={{
                      state: !!error?.message,
                      message: error?.message,
                    }}
                    value={value}
                    placeholder="Tempo de empregado (em anos)"
                    onChange={onChange}
                  />
                )}
              />

              <Controller
                control={control}
                name="cycleId"
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <SelectEntry
                    error={error?.message}
                    loading={selectCyclesLoading}
                    onChange={onChange}
                    value={value}
                    placeholder="Dia de vencimento do cartão"
                    data={cycles}
                    optionsProperties={{
                      key: 'id',
                      name: 'day',
                      value: 'id',
                    }}
                  />
                )}
              />
            </Form>
          </Content>

          <FixedToBottom>
            <ButtonFilled onClick={handleSubmit(onSubmit)}>Avançar</ButtonFilled>
          </FixedToBottom>
        </>
      ) : (
        <ErrorConnectContainer>
          <ErrorConnect
            loading={buttonLoading}
            onClick={() => {
              errorConnect.callbacks.forEach(callback => callback());
            }}
          />
        </ErrorConnectContainer>
      )}
    </Container>
  );
}
