import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';

import { Container } from './styles';

type GlobalLoadingProps = {
  backgroundColor?: string;
};

export default function GlobalLoading({ backgroundColor }: GlobalLoadingProps): JSX.Element {
  return (
    <Container backgroundColor={backgroundColor}>
      <LoadingOutlined />
    </Container>
  );
}
