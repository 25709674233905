import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BiometryPicturePreviewData } from '../../../contexts/BiometryPicturePreview';
import useDialog from '../../../hooks/useDialog';
import SavePictureService from '../../../services/SavePictureService';
import ButtonFilled from '../../atoms/ButtonFilled';
import ButtonOutline from '../../atoms/ButtonOutline';
import Paragraph from '../../atoms/Paragraph';
import { Container, ImagePreview, GroupButtons, BoxFixed, Image } from './styles';

type BiometryPicturePreviewProps = BiometryPicturePreviewData & {
  close: () => void;
};

export default function BiometryPicturePreview({
  type,
  base64,
  message,
  nextPage,
  proposalId,
  close,
}: BiometryPicturePreviewProps): JSX.Element {
  const navigate = useNavigate();
  const { openDialog } = useDialog();
  const [loading, setLoading] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const handlerSaveImage = async () => {
    try {
      setLoading(true);
      setButtonDisabled(true);
      await SavePictureService.execute({ type, base64, proposalId });
      navigate(nextPage);
    } catch (err: any) {
      openDialog({
        title: 'Ops, ocorreu um erro',
        type: 'danger',
        message: err?.response?.data?.message,
      });
    } finally {
      setLoading(false);
      setButtonDisabled(false);
      close();
    }
  };

  return (
    <Container>
      <ImagePreview>
        <Image src={base64} alt="" />
        <BoxFixed>
          <Paragraph>{message}</Paragraph>
          <GroupButtons>
            <ButtonOutline onClick={close} data-testid="Button">
              Não, tirar outra
            </ButtonOutline>
            <ButtonFilled
              disabled={buttonDisabled}
              onClick={() => handlerSaveImage()}
              loading={loading}
            >
              Sim, enviar
            </ButtonFilled>
          </GroupButtons>
        </BoxFixed>
      </ImagePreview>
    </Container>
  );
}
