import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;
  height: 92vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  text-align: center;
  margin-bottom: 5px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 0px 30px 0px 30px;
  height: 35vh;
  margin-bottom: 30vh;
  text-align: center;
  h1,
  p {
    font-weight: 700;
  }
`;
