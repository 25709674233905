import styled from 'styled-components';

export const View = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;

  img {
    width: 65px;
    height: 65px;
  }
`;

export const ViewParagraph = styled.div`
  margin-top: 20px;

  p {
    text-align: center;
  }
`;

export const ViewButton = styled.div`
  margin-top: 39px;
`;
