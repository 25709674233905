import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import ButtonFilled from '../../components/atoms/ButtonFilled';
import Header from '../../components/atoms/Header';
import Title from '../../components/atoms/Title';
import TermsOfContract from '../../components/molecules/TermsOfContract';
import useProposal from '../../hooks/useProposal';
import getTermsUrl from '../../utils/getTermsUrl';
import { Container, ButtonContainer } from './style';

const InsuranceTerm = (): JSX.Element => {
  const { proposal } = useProposal();
  const {
    state: { slug, insuranceId },
  } = useLocation();
  const navigate = useNavigate();

  const insuranceTermUrl = getTermsUrl(proposal.id, slug);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container data-testid="insuranceterm">
      <Header />
      <Title>Termos do seguro</Title>

      <TermsOfContract data-testid="iframeTerms" src={insuranceTermUrl} />

      <ButtonContainer>
        <ButtonFilled
          onClick={() =>
            navigate(`/digital-signature`, {
              state: {
                type: 'insurance',
                insurance_id: insuranceId,
                next_route: '/contract-insurances',
              },
            })
          }
        >
          Concordar e assinar
        </ButtonFilled>
      </ButtonContainer>
    </Container>
  );
};

export default InsuranceTerm;
