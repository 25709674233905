import styled from 'styled-components';

export const Container = styled.ul`
  li {
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;
