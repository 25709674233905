import styled from 'styled-components';

export const Container = styled.footer`
  background-color: ${({ theme: { colors } }) => colors.white};

  width: 100%;

  padding: 5px 24px;
  position: fixed;
  left: 0;
  bottom: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
`;
