import api from '../../http/api';

import { IStore } from '../../models/IStore';

export type IReponse = {
  data: IStore[];
  last_page: number;
};
export default class GetStoreService {
  public static async execute(paramSearch: string, pageNumber: number): Promise<IReponse> {
    const response = await api.get(`self-attendance/commercial-origins/`, {
      params: {
        items_per_page: '10',
        page: pageNumber,
        'where_like[field]': 'name',
        'where_like[type]': 'have',
        'where_like[value]': paramSearch,
        active: 'true',
      },
      timeout: 3000,
      timeoutErrorMessage: 'Network Error',
    });

    return response.data;
  }
}
