type Error = {
  message: string;
  response?: {
    status?: number;
    data: {
      message: string | string[];
    };
  };
};

type ErrorHandlingData = {
  type: 'ErrorConnect' | 'ResponseError' | 'GenericError';
  message: string | string[];
};

export default function errorHandling({ message, response }: Error): ErrorHandlingData {
  if (message === 'Network Error' || response?.status === 500) {
    return {
      type: 'ErrorConnect',
      message: '',
    };
  }

  if (response?.data) {
    if (response.data.message) {
      return {
        type: 'ResponseError',
        message: response.data.message,
      };
    }
  }

  return {
    type: 'GenericError',
    message,
  };
}
