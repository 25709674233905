import styled from 'styled-components';

type ViewProps = {
  width?: number;
  heigth?: number;
  backgroundColor: string;
};

export const View = styled.div<ViewProps>`
  display: flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.backgroundColor};
  width: ${props => props.width || 30}px;
  height: ${props => props.heigth || 30}px;
`;
