import api from '../../http/api';
import { Proposal } from '../../models/Proposal';

export default class SendFullValidationService {
  public static async execute(proposalPayload: Omit<Proposal, 'decision'>): Promise<Proposal> {
    const {
      data: { proposal },
    } = await api.post(
      'self-attendance/full-validation',
      {
        ...proposalPayload,
      },
      {
        timeout: 6000,
        timeoutErrorMessage: 'Network Error',
      },
    );

    return proposal as Proposal;
  }
}
