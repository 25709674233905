import React from 'react';
import { Route, Routes } from 'react-router-dom';

import Welcome from '../screens/Welcome';
import CameraDisabled from '../screens/CameraDisabled';
import ContractInsurances from '../screens/ContractInsurances';
import CreditCardTerm from '../screens/CreditCardTerm';
import InsuranceTerm from '../screens/InsuranceTerm';
import LGPDTerm from '../screens/LGPDTerm';
import ProposalScreenResult from '../screens/ProposalResult';
import Signature from '../screens/Signature';
import TutorialCamera from '../screens/TutorialCamera';
import ProposalOneFLowPersonalData from '../screens/ProposalOneFLowPersonalData';
import PersonalTwoFlowSelectStore from '../screens/PersonalTwoFlowSelectStore';
import PersonalTwoFlowProfessionalData from '../screens/PersonalTwoFlowProfessionalData';
import PersonalTwoFlowAddress from '../screens/PersonalTwoFlowAddress';
import ProposalTwoFlowPersonalData from '../screens/ProposalTwoFlowPersonalData';
import PreparePictureSelfie from '../screens/PreparePictureSelfie';
import PreparePictureDocument from '../screens/PreparePictureDocument';
import PreparePictureDocumentBack from '../screens/PreparePictureDocumentBack';
import ProposalOneFlowSMSValidation from '../screens/ProposalOneFlowSMSValidation';
import BiometryPictureFace from '../screens/BiometryPictureFace';
import BiometryPictureDocumentFront from '../screens/BiometryPictureDocumentFront';
import BiometryPictureDocumentBack from '../screens/BiometryPictureDocumentBack';
import PreparePictureDocumentResidence from '../screens/PreparePictureDocumentResidence';
import BiometryPictureDocumentResidence from '../screens/BiometryPictureDocumentResidence';

export default function AppRoutes(): JSX.Element {
  return (
    <Routes>
      <Route path="/" element={<Welcome />} />

      <Route path="/proposal-one-flow-personal-data" element={<ProposalOneFLowPersonalData />} />
      <Route path="/proposal-one-flow-sms-validation" element={<ProposalOneFlowSMSValidation />} />

      <Route path="/proposal-two-flow-personal-data" element={<ProposalTwoFlowPersonalData />} />
      <Route path="/proposal-two-flow-address" element={<PersonalTwoFlowAddress />} />
      <Route
        path="/proposal-two-flow-professional-data"
        element={<PersonalTwoFlowProfessionalData />}
      />
      <Route path="/proposal-two-flow-select-store" element={<PersonalTwoFlowSelectStore />} />

      <Route path="/tutorial-camera" element={<TutorialCamera />} />
      <Route path="/camera-disabled" element={<CameraDisabled />} />

      <Route path="/prepare-picture-selfie" element={<PreparePictureSelfie />} />
      <Route path="/biometry-picture-face" element={<BiometryPictureFace />} />

      <Route path="/prepare-picture-document" element={<PreparePictureDocument />} />
      <Route path="/biometry-picture-document-front" element={<BiometryPictureDocumentFront />} />

      <Route path="/prepare-picture-document-back" element={<PreparePictureDocumentBack />} />
      <Route path="/biometry-picture-document-back" element={<BiometryPictureDocumentBack />} />

      <Route
        path="/prepare-picture-document-residence"
        element={<PreparePictureDocumentResidence />}
      />
      <Route
        path="/biometry-picture-document-residence"
        element={<BiometryPictureDocumentResidence />}
      />

      <Route path="/credit-card-term" element={<CreditCardTerm />} />
      <Route path="/lgpd-term" element={<LGPDTerm />} />
      <Route path="/digital-signature" element={<Signature />} />

      <Route path="/contract-insurances" element={<ContractInsurances />} />
      <Route path="/insurance-term" element={<InsuranceTerm />} />

      <Route path="/proposal-result" element={<ProposalScreenResult />} />
    </Routes>
  );
}
