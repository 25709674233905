import React, { LiHTMLAttributes } from 'react';
import { useTheme } from 'styled-components';
import Paragraph from '../../atoms/Paragraph';
import IconWithRoundBackground from '../IconWithRoundBackground';
import { Item, Content } from './styles';
import { IStore } from '../../../models/IStore';

export type StoreItemProps = LiHTMLAttributes<HTMLLIElement> & {
  data: IStore;
  onClick?: () => void;
  selected?: boolean;
  height?: number;
};

const StoreItem = ({ height, onClick, data, selected, ...rest }: StoreItemProps): JSX.Element => {
  const { colors, fonts } = useTheme();

  return (
    <Item height={height} onClick={onClick} selected={selected} {...rest}>
      <div>
        <Paragraph
          fontSize={fonts.title.size}
          color={colors.black}
          style={{ textTransform: 'uppercase', paddingBottom: 2 }}
        >
          {data.name}
        </Paragraph>
        <Paragraph color={colors.gray300}>
          Endereço: Nº {data.address_number}, {data.complement}
        </Paragraph>
        <Paragraph color={colors.gray300}>
          Localização: {data.district}, {data.city}, {data.state}, CEP: {data.cep}
        </Paragraph>
      </div>
      <Content>
        {selected && (
          <IconWithRoundBackground
            icon={{ color: colors.white, name: 'FiCheck', size: 26 }}
            backgroundColor={colors.success}
            width={32}
            heigth={32}
          />
        )}
      </Content>
    </Item>
  );
};
export default StoreItem;
