import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${props => props.theme.colors.black};
`;

export const Header = styled.div`
  z-index: 1;
  display: flex;
  width: 100vw;
  align-items: center;
  height: 80px;
  justify-content: space-between;
  p {
    margin: 0 5px 0 0;
    font-size: 18px;
    margin-left: 15px;
  }
`;

export const Content = styled.div`
  width: 100vw;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Overlay = styled.div`
  position: absolute;
  width: 100vw;
  height: 100%;
  display: flex;
  max-width: 800px;
`;

export const Footer = styled.div`
  z-index: 9;
  padding: 5px 24px;
  width: 100%;
`;
