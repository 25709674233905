import React, { InputHTMLAttributes, useState, forwardRef } from 'react';

import onlyNumbers from '../../../utils/onlyNumbers';
import { Container, Content } from './styles';

type NumberEntryProps = InputHTMLAttributes<HTMLInputElement> & {
  onChange: (text: string) => void;
  error?: {
    state: boolean;
    message?: string;
  };
  width?: number;
};

function NumberEntry(
  { onChange, error, width, ...rest }: NumberEntryProps,
  ref: React.Ref<HTMLInputElement>,
): JSX.Element {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <Container>
      <Content
        width={width}
        data-testid="NumberEntryContainer"
        isFocused={isFocused}
        isErrored={!!error?.state}
      >
        <input
          data-testid="NumberEntryInput"
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          type="text"
          inputMode="numeric"
          onChange={e => {
            onChange(onlyNumbers(e.target.value));
          }}
          ref={ref}
          {...rest}
        />
      </Content>
      {error?.message && <span>{error.message}</span>}
    </Container>
  );
}

export default forwardRef(NumberEntry);
