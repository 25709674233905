import React, { LiHTMLAttributes } from 'react';

import Paragraph from '../../atoms/Paragraph';
import { Container, StepNumberContainer, ParagraphContainer } from './styles';

type StepItemProps = LiHTMLAttributes<HTMLLIElement> & {
  number: number;
  children: string;
};

export default function StepItem({ children, number, ...rest }: StepItemProps): JSX.Element {
  return (
    <Container {...rest}>
      <StepNumberContainer>{number}</StepNumberContainer>

      <ParagraphContainer>
        <Paragraph>{children}</Paragraph>
      </ParagraphContainer>
    </Container>
  );
}
