import React, { HTMLAttributes } from 'react';
import { useTheme } from 'styled-components';
import { View, ViewParagraph, ViewButton } from './styles';
import IconSad from '../../../assets/icon-sad.png';
import Paragraph from '../../atoms/Paragraph';
import ButtonOutline from '../../atoms/ButtonOutline';

type ErrorConnectProps = HTMLAttributes<HTMLDivElement> & {
  onClick: () => void;
  loading?: boolean;
};

const ErrorConnect = ({ onClick, loading, ...rest }: ErrorConnectProps): JSX.Element => {
  const { colors } = useTheme();
  return (
    <View {...rest}>
      <div>
        <img src={IconSad} alt="iconSad" />
      </div>

      <ViewParagraph>
        <Paragraph color={colors.gray100}>Ops, desculpe</Paragraph>
        <Paragraph color={colors.gray100}>Ocorreu um erro durante a solicitação</Paragraph>
      </ViewParagraph>

      <ViewButton>
        <ButtonOutline data-testid="Button" onClick={onClick} loading={loading}>
          Tente Novamente
        </ButtonOutline>
      </ViewButton>
    </View>
  );
};

export default ErrorConnect;
