import React, { HTMLAttributes, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import ButtonFilled from '../../components/atoms/ButtonFilled';
import Logo from '../../components/atoms/Logo';
import Paragraph from '../../components/atoms/Paragraph';
import useApplicationConfig from '../../hooks/useApplicationConfig';
import { Container, Content } from './style';

const Welcome = ({ ...rest }: HTMLAttributes<HTMLDivElement>): JSX.Element => {
  const navigate = useNavigate();
  const { applicationConfig } = useApplicationConfig();

  const theme = useTheme();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container urlImage={applicationConfig.background_image_url} {...rest}>
      <Content>
        <Logo className="Logo" source={applicationConfig.logo_url} />
        <Paragraph className="welcomeMessage" fontSize={theme.fonts.paragraph.size}>
          {applicationConfig.welcome_message}
        </Paragraph>
        <div className="box-notify-scr">
          <Paragraph>
            Ao iniciar, você autoriza o Emissor a consultar o sistema de Informações de Crédito
            (SCR) do Banco Central e está ciente de que seus dados serão registrados no SCR.
          </Paragraph>
          <Paragraph style={{ paddingTop: 5 }}>Proposta sujetia à analise de crédito.</Paragraph>
        </div>
        <ButtonFilled
          data-testid="buttonNavigate"
          onClick={() => navigate(`/proposal-one-flow-personal-data`)}
          className="ButtonView"
        >
          Iniciar
        </ButtonFilled>
      </Content>
    </Container>
  );
};

export default Welcome;
