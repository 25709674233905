import React from 'react';
import * as Icons from 'react-icons/fi';
import { IconBaseProps } from 'react-icons';
import { IconType } from '../../../models/Icons';

type IconProps = IconBaseProps & {
  name: IconType;
};

const Icon = ({ name, ...rest }: IconProps): JSX.Element => {
  const FeatherIcon = Icons[name];

  return <FeatherIcon data-testid="FeatherIcon" {...rest} />;
};

export default Icon;
