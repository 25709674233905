import styled from 'styled-components';

type ViewProps = {
  width?: number;
};

export const View = styled.div<ViewProps>`
  width: ${props => props.width || 275}px;
`;

export const Image = styled.img`
  width: 100%;
`;
