import api from '../http/api';

type SmsValidationData = {
  id: string;
  code: string;
};

type SmsValidationResponse = {
  message: string;
};

export default class SmsService {
  public static async validationSms({
    code,
    id,
  }: SmsValidationData): Promise<SmsValidationResponse> {
    const { data } = await api.post('/self-attendance/validation-sms-code', {
      code,
      id,
    });

    return data;
  }

  public static async requestSms(id: string): Promise<SmsValidationData> {
    const { data } = await api.get(`/self-attendance/send-sms-code/${id}`);

    return data;
  }
}
