import { DefaultTheme } from 'styled-components';

import blue from './themes/blue';
import red from './themes/red';

type Themes = {
  [key: string]: DefaultTheme;
};

const themes: Themes = {
  blue,
  red,
};

export default themes;
