import React, { useCallback, useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { useTheme } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/atoms/Header';
import Title from '../../components/atoms/Title';
import ErrorConnect from '../../components/molecules/ErrorConnect';
import SearchBox from '../../components/molecules/SearchBox';
import StoreItem from '../../components/molecules/StoreItem';
import useDebounceState from '../../hooks/UseDebounce';
import useDialog from '../../hooks/useDialog';
import useProposal from '../../hooks/useProposal';
import { IStore } from '../../models/IStore';
import GetStoreService from '../../services/GetStoreService';
import errorHandling from '../../utils/errorHandling';
import { Container, Content, ErroDiv } from './style';
import useApplicationConfig from '../../hooks/useApplicationConfig';

type SetStoreData = {
  paramSearch: string;
  scrollUpdate?: boolean;
  pageNumber?: number;
};

const PersonalTwoFlowSelectStore = (): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [stores, setStores] = React.useState<IStore[]>([]);
  const [errorConnect, setErrorConnect] = React.useState(false);
  const [search, setSearch, debounceSearch] = useDebounceState('');
  const [currentPage, setCurrentPage] = React.useState(1);
  const [lastPage, setLastPage] = React.useState(0);
  const sentinelRef = React.useRef<HTMLLIElement>(null);
  const { colors } = useTheme();
  const { proposal, setProposal } = useProposal();
  const { openDialog } = useDialog();
  const { applicationConfig } = useApplicationConfig();
  const navigate = useNavigate();

  const selectStore = (idStore: string, nameStore?: string) => {
    setProposal({
      ...proposal,
      origin_receiving_card_id: idStore,
      origin_receiving_card_name: nameStore,
    });
    navigate('/tutorial-camera');
  };

  const loadStore = useCallback(
    async ({ pageNumber = 1, scrollUpdate, paramSearch }: SetStoreData) => {
      try {
        setLoadingButton(true);
        setLoading(true);
        const data = await GetStoreService.execute(paramSearch, pageNumber);
        setLastPage(data.last_page);
        setStores(preStore => (scrollUpdate ? [...preStore, ...data.data] : data.data));
        setCurrentPage(statePage => statePage + 1);
        setLoadingButton(false);
        setErrorConnect(false);
      } catch (error: any) {
        const { type, message } = errorHandling(error);
        if (type === 'ErrorConnect') {
          setErrorConnect(true);
          return;
        }
        openDialog({
          title: 'Ocorreu um erro no envio das informações',
          message,
          type: 'danger',
        });
        setErrorConnect(false);
      } finally {
        setLoadingButton(false);
        setLoading(false);
      }
    },
    [openDialog],
  );

  React.useEffect(() => {
    setCurrentPage(1);
    loadStore({ paramSearch: debounceSearch, pageNumber: 1 });
  }, [debounceSearch, loadStore]);

  React.useEffect(() => {
    const intersectionObserver = new IntersectionObserver(entries => {
      if (
        entries.some(entry => entry.isIntersecting && stores.length > 0 && currentPage <= lastPage)
      ) {
        intersectionObserver.disconnect();
        loadStore({ paramSearch: debounceSearch, scrollUpdate: true, pageNumber: currentPage });
      }
    });

    if (!sentinelRef.current) {
      return () => intersectionObserver.disconnect();
    }

    intersectionObserver.observe(sentinelRef.current);
    return () => intersectionObserver.disconnect();
  }, [loadStore, debounceSearch, currentPage, stores.length, lastPage]);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container>
      <Header />
      {errorConnect ? (
        <ErroDiv data-testid="Error">
          <ErrorConnect
            loading={loadingButton}
            onClick={() => {
              loadStore({ paramSearch: debounceSearch });
            }}
          />
        </ErroDiv>
      ) : (
        <Container data-testid="StoreSelect">
          <div>
            <Title>Onde gostaria de receber seu {applicationConfig.product.title}?</Title>
          </div>
          <Content>
            <SearchBox
              height={42}
              value={search}
              data-testid="EntrySearch"
              onChange={text => setSearch(text as string)}
              placeholder="Pesquise aqui ..."
              onClick={() => {
                setCurrentPage(1);
                loadStore({
                  paramSearch: debounceSearch,
                  pageNumber: currentPage,
                });
              }}
            />
            <ul data-testid="Stores">
              {stores.map(store => (
                <StoreItem
                  key={store.id}
                  data={store}
                  selected={store.id === proposal.origin_receiving_card_id}
                  onClick={() => selectStore(store.id, store.name)}
                />
              ))}
              {loading && (
                <LoadingOutlined
                  style={{ fontSize: 40, marginLeft: '45%', color: colors.gray100 }}
                />
              )}
              <li ref={sentinelRef} id="sentinel" data-testid="sentinel" />
            </ul>
          </Content>
        </Container>
      )}
    </Container>
  );
};

export default PersonalTwoFlowSelectStore;
