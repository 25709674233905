export function cpfMask(text: string): string {
  return text
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2');
}

export function dateMask(text: string): string {
  return text
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{2})(\d)/, '$1/$2');
}

export function zipCodeMask(text: string): string {
  return text.replace(/\D/g, '').replace(/(\d)(\d{3})$/, '$1-$2');
}

export function cellphoneMask(text: string): string {
  return text.replace(/\D/g, '').replace(/(\d)(\d{4})$/, '$1-$2');
}
