import React, { ImgHTMLAttributes } from 'react';
import { View, Image } from './styles';

type LogoProps = ImgHTMLAttributes<HTMLImageElement> & {
  width?: number;
  source: string;
};

export default function Logo({ width, source, ...rest }: LogoProps): JSX.Element {
  return (
    <View data-testid="View" width={width} {...rest}>
      <Image src={source} />
    </View>
  );
}
