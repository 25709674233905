import api from '../http/api';
import { ICycle } from '../models/Cycle';

export default class GetCyclesService {
  public static async execute(): Promise<ICycle[]> {
    const { data } = await api.get<ICycle[]>(`self-attendance/cycles`);

    return data;
  }
}
