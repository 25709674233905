import React, { ReactNode } from 'react';
import { ThemeProvider } from 'styled-components';

import DialogProvider from './Dialog';
import ProposalProvider from './Proposal';
import themes from '../styles';
import blue from '../styles/themes/blue';
import useApplicationConfig from '../hooks/useApplicationConfig';
import BiometryPicturePreviewProvider from './BiometryPicturePreview';

type AppProviderProps = {
  children: ReactNode;
};

export default function AppProvider({ children }: AppProviderProps): JSX.Element {
  const { applicationConfig } = useApplicationConfig();

  return (
    <ThemeProvider theme={themes[applicationConfig.theme_name] || blue}>
      <DialogProvider>
        <BiometryPicturePreviewProvider>
          <ProposalProvider>{children}</ProposalProvider>
        </BiometryPicturePreviewProvider>
      </DialogProvider>
    </ThemeProvider>
  );
}
