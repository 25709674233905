import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import GloblalStyle from './styles/global';
import useApplicationConfig from './hooks/useApplicationConfig';
import GlobalLoading from './components/atoms/GlobalLoading';
import GetApplicationConfigService from './services/GetApplicationConfigService';
import AppRoutes from './routes';
import AppProvider from './contexts/AppProvider';
import ErrorConnect from './components/molecules/ErrorConnect';

const App = (): JSX.Element => {
  const [appLoading, setAppLoading] = useState(false);
  const [errorConnect, setErrorConnect] = useState(false);

  const { pathname } = useLocation();

  const { setApplicationConfig } = useApplicationConfig();

  const getApplicationConfig = useCallback(async () => {
    try {
      setAppLoading(true);
      const applicationConfigData = await GetApplicationConfigService.execute();

      setApplicationConfig(applicationConfigData);
      setErrorConnect(false);
    } catch (error: any) {
      setErrorConnect(true);
    } finally {
      setAppLoading(false);
    }
  }, [setApplicationConfig]);

  const listener = (event: BeforeUnloadEvent) => {
    event.preventDefault();

    // eslint-disable-next-line no-param-reassign
    event.returnValue = '';
  };

  useEffect(() => {
    getApplicationConfig();
  }, [getApplicationConfig]);

  useEffect(() => {
    if (pathname === '/proposal-result') {
      return () => window.removeEventListener('beforeunload', listener);
    }

    window.addEventListener('beforeunload', listener);

    return () => window.removeEventListener('beforeunload', listener);
  }, [pathname]);

  return (
    <AppProvider>
      <GloblalStyle />

      {!errorConnect ? (
        <>{appLoading ? <GlobalLoading /> : <AppRoutes />}</>
      ) : (
        <div
          style={{
            width: '100vw',
            height: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <ErrorConnect loading={appLoading} onClick={() => getApplicationConfig()} />
        </div>
      )}
    </AppProvider>
  );
};

export default App;
