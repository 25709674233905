import React, { HTMLAttributes, ReactNode } from 'react';

import { H1 } from './styles';

type TitleProps = HTMLAttributes<HTMLHeadingElement> & {
  children: ReactNode;
  color?: string;
  fontSize?: number;
};

export default function Title({ children, color, fontSize, ...rest }: TitleProps): JSX.Element {
  return (
    <H1 fontSize={fontSize} color={color} {...rest}>
      {children}
    </H1>
  );
}
