import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 90vh;
  h1 {
    text-align: start;
    padding: 4vw 6vw;
  }
`;
export const Content = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  padding: 6vw;
  justify-content: baseline;
  ul {
    padding: 4vh 0px;
  }
  li {
    list-style: none;
    margin: 0px 0px 5px;
  }
  #sentinel {
    height: 10px;
  }
`;

export const ErroDiv = styled.div`
  width: 100%;
  height: 90vh;

  @media only screen and (max-width: 600px) {
    button {
      width: 340px;
    }
  }
`;
