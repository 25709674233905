import styled from 'styled-components';

type ItemProps = {
  selected?: boolean;
  height?: number;
};

export const Item = styled.li<ItemProps>`
  display: flex;
  background-color: ${props => props.theme.colors.white};
  width: 100%;
  height: ${props => props.height || 102}px;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  padding: 0px 15px 0px 15px;
  border: ${props =>
    props.selected
      ? `2px solid ${props.theme.colors.success}`
      : `1px solid ${props.theme.colors.gray50}`};
  &:active {
    opacity: 0.8;
  }
  div {
    text-align: start;
  }
`;

export const Content = styled.div`
  width: 32px;
  height: 32px;
  padding-top: 2px;
`;
