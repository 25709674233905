import styled from 'styled-components';

export const Container = styled.li`
  width: 340px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0 5px;
  list-style-type: none;
`;

export const ParagraphContainer = styled.div`
  width: 280px;

  p {
    font-weight: 500;
    font-size: 18px;
  }
`;

export const StepNumberContainer = styled.div`
  width: 40px;
  height: 40px;

  border-radius: 50%;

  background-color: ${({ theme: { colors } }) => colors.primary};

  display: flex;
  align-items: center;
  justify-content: center;

  font-family: ${({ theme: { fonts } }) => fonts.paragraph.family};
  font-weight: 500;
  color: ${({ theme: { colors } }) => colors.white};
`;
