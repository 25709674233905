import styled, { css } from 'styled-components';

type ButtonProps = {
  width?: number;
  height?: number;
  backgroundColor?: string;
};

export const Button = styled.button<ButtonProps>`
  background-color: ${({ backgroundColor, theme }) => backgroundColor || theme.colors.primary};

  border-radius: 16px;
  border: 0;
  outline: 0;

  width: ${props => (props.width ? `${props.width}px` : '100%')};
  height: ${props => props.height || 45}px;
  padding: 8px 24px;

  font-size: 14px;
  font-family: ${props => props.theme.fonts.paragraph.family};
  font-weight: 500;
  color: ${props => props.theme.colors.white};

  transition: opacity 0.2s ease-out;

  ${props =>
    props.disabled &&
    css`
      opacity: 0.5;
    `}

  &:active {
    opacity: 0.8;
  }
`;
