import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  @media only screen and (max-width: 600px) {
    button {
      width: 90%;
    }
  }
`;

export const Content = styled.div`
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h1 {
    margin: 30px 8px 30px 8px;
    font-weight: 700;
    text-align: center;
  }
  @media only screen and (max-height: 600px) {
    div {
      margin: 0 15px 0 15px;
    }
  }
`;
