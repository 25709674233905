import styled from 'styled-components';

export const Container = styled.div`
  @media (max-width: 375px) {
    button {
      width: 260px;
    }
  }
`;

export const Content = styled.main`
  padding: 0 25px;
`;

export const Form = styled.form`
  margin-top: 20px;

  div {
    margin-bottom: 10px;
  }
`;

export const CellphoneSection = styled.section`
  display: flex;
  justify-content: space-between;

  div {
    &:nth-child(2) {
      margin-left: 10px;
    }
  }
`;

export const Footer = styled.footer`
  margin-top: 25px;
  padding: 0 22px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;

  p {
    margin-bottom: 15px;
    text-align: center;
    padding: 0 10px;

    & > a {
      text-decoration: none;
      color: ${({ theme: { colors } }) => colors.primary};
      font-weight: 700;
    }
  }
`;

export const ErrorConnectContainer = styled.div`
  width: 100vw;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;
`;
