import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  h1 {
    margin: 0 15px 10px;
    font-weight: bold;
  }

  button {
    width: 90vw;
  }

  iframe {
    align-self: center;
    width: 95%;
    height: 75vh;

    @media (max-width: 320px) {
      width: 88%;
    }
  }
`;

export const ButtonContainer = styled.div`
  padding-top: 15px;
  margin-bottom: 15px;

  display: flex;
  align-items: center;
  justify-content: center;
`;
