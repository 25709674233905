import React, { useState, InputHTMLAttributes } from 'react';
import { useTheme } from 'styled-components';
import Icon from '../../atoms/Icon';
import { Container, IconButton } from './styles';

type SearchBoxProps = InputHTMLAttributes<HTMLInputElement> & {
  onChange: (text: string) => void;
  onClick: () => void;
  height?: number;
};

const SearchBox = ({ onChange, onClick, height, ...rest }: SearchBoxProps): JSX.Element => {
  const [isFocused, setIsFocused] = useState(false);
  const { colors } = useTheme();
  return (
    <Container height={height} data-testid="SearchBoxContainer" isFocused={isFocused}>
      <input
        data-testid="SearchBox"
        type="text"
        onChange={e => {
          onChange(e.target.value);
        }}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        {...rest}
      />
      <IconButton data-testid="IconButton" onClick={onClick}>
        <Icon name="FiSearch" size={30} color={colors.gray200} />
      </IconButton>
    </Container>
  );
};

export default SearchBox;
