import React, { HTMLAttributes } from 'react';
import { Text } from './styles';

type ParagraphProps = HTMLAttributes<HTMLParagraphElement> & {
  fontSize?: number;
  color?: string;
  children: React.ReactNode;
};

const Paragraph = ({ fontSize, color, children, ...rest }: ParagraphProps): JSX.Element => {
  return (
    <Text fontSize={fontSize} color={color} {...rest}>
      {children}
    </Text>
  );
};

export default Paragraph;
