import React, { HTMLAttributes } from 'react';
import { View } from './styles';
import Icon from '../../atoms/Icon';
import { IconType } from '../../../models/Icons';

type IconWithRoundBackgroundProps = HTMLAttributes<HTMLDivElement> & {
  width?: number;
  heigth?: number;
  backgroundColor: string;
  icon: {
    name: IconType;
    color: string;
    size?: number;
  };
};

const IconWithRoundBackground = ({
  width,
  heigth,
  icon,
  backgroundColor,
  ...rest
}: IconWithRoundBackgroundProps): JSX.Element => {
  return (
    <View width={width} heigth={heigth} backgroundColor={backgroundColor} {...rest}>
      <Icon data-testid="Icon" name={icon.name} color={icon.color} size={icon.size || 20} />
    </View>
  );
};

export default IconWithRoundBackground;
