import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 325px;
    margin-top: 35px;

    @media (max-width: 320px) {
      width: 250px;
    }
  }

  h1 {
    @media (max-width: 320px) {
      font-size: 18px;
    }
  }

  p {
    @media (max-width: 320px) {
      font-size: 14px;
    }
    font-weight: 500;
  }
`;

export const Content = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;
