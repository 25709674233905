import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  height: 100vh;
  flex-direction: column;
`;

export const ViewDigitalSignature = styled.div`
  width: 100%;
  border-bottom: 1px solid ${props => props.theme.colors.black};
`;

export const ViewButtons = styled.div`
  width: 100%;
  height: 30%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  button {
    width: 40%;
  }
`;
