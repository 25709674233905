import React, { SelectHTMLAttributes } from 'react';
import { LoadingOutlined } from '@ant-design/icons';

import { Container, Content } from './styles';

type Option = {
  key: string;
  name: string;
  value: string;
};

type Data = {
  [key: string]: any;
};

type SelectEntryProps = SelectHTMLAttributes<HTMLSelectElement> & {
  placeholder: string;
  data: Data[];
  optionsProperties: Option;
  onChange: (value: string) => void;
  error?: string;
  loading?: boolean;
};

export default function SelectEntry({
  placeholder,
  data,
  optionsProperties,
  onChange,
  error,
  loading,
  ...rest
}: SelectEntryProps): JSX.Element {
  return (
    <Container>
      <Content isErrored={!!error}>
        {loading ? (
          <LoadingOutlined />
        ) : (
          <select defaultValue="" onChange={e => onChange(e.target.value)} {...rest}>
            <option value="" disabled>
              {placeholder}
            </option>
            {data.map(item => (
              <option
                data-testid="SelectEntryOption"
                key={item[optionsProperties.key]}
                value={item[optionsProperties.value]}
              >
                {item[optionsProperties.name]}
              </option>
            ))}
          </select>
        )}
      </Content>
      {error && <span>{error}</span>}
    </Container>
  );
}
