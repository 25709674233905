import styled from 'styled-components';

export const Container = styled.div`
  @media (max-width: 375px) {
    button {
      width: 260px;
    }
  }
`;

export const Content = styled.main`
  padding: 0 20px;

  h1 {
    margin-bottom: 10px;
  }

  @media (max-width: 500px) {
    h1 {
      width: 270px;
    }
  }

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      color: ${({ theme: { colors } }) => colors.gray200};
      font-size: 32px;
    }
  }
`;

export const List = styled.ul`
  height: 65vh;
  overflow-y: scroll;

  padding: 10px 0;

  @media (max-height: 650px) {
    padding: 10px 0 50px 0;
  }

  @media (max-height: 500px) {
    height: 60vh;
  }
`;

export const ListItem = styled.li`
  width: 100%;
  margin-bottom: 10px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  & > button {
    border: 0;
    outline: 0;
    background-color: transparent;

    width: 50px;

    margin-left: 10px;

    svg {
      font-size: 32px;
      color: ${({ theme: { colors } }) => colors.danger};
    }

    &:active {
      opacity: 0.7;
      transition: 0.1s;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const ErrorConnectContainer = styled.div`
  width: 100vw;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;
`;
