import React, { InputHTMLAttributes, useState } from 'react';
import { cellphoneMask } from '../../../utils/masks';
import { Container, Content } from './styles';

type CellphoneEntry = InputHTMLAttributes<HTMLInputElement> & {
  onChange: (text: string) => void;
  error?: string;
};

const CellphoneEntry = ({ onChange, error, ...rest }: CellphoneEntry): JSX.Element => {
  const [isFocused, setIsFocused] = useState(false);
  return (
    <Container>
      <Content data-testid="CellphoneEntryContent" isErrored={!!error} isFocused={isFocused}>
        <input
          data-testid="CellphoneEntry"
          type="text"
          placeholder="Telefone"
          onChange={e => {
            onChange(cellphoneMask(e.target.value));
          }}
          inputMode="numeric"
          maxLength={10}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          {...rest}
        />
      </Content>
      {error && <span>{error}</span>}
    </Container>
  );
};

export default CellphoneEntry;
