import styled from 'styled-components';

type ContinerProps = {
  urlImage: string;
};

export const Container = styled.div<ContinerProps>`
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 50vh;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(${props => props.urlImage});
  background-color: black;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  padding: 0px 0px 15px 0px;
  position: absolute;
  background-color: ${props => props.theme.colors.white};
  border-radius: 16px 16px 0px 0px;
  height: 64vh;
  max-height: 64vh;
  width: 88%;
  left: 6vw;
  top: 40vh;

  .box-notify-scr {
    width: 80vw;
    text-align: left;
    border-radius: 10px;
    padding: 20px 10px 20px 10px;
    margin-top: auto;
    background-color: ${props => props.theme.colors.white200};

    p {
      font-size: 10px;
    }
  }

  div.Logo {
    padding-top: 10px;
    max-width: 80vw;
  }

  p.welcomeMessage {
    font-weight: 800;
    padding: 20px 50px 20px 50px;
  }

  button.ButtonView {
    justify-content: center;
    align-items: center;
    width: 80vw;
    margin-top: 5px;
    padding: 15px;
  }
`;
