import api from '../http/api';
import { IInsurance } from '../models/Insurance';

export default class GetInsurancesService {
  public static async execute(proposalId: string): Promise<IInsurance[]> {
    const { data } = await api.get<IInsurance[]>(`/self-attendance/insurances/${proposalId}`);

    return data;
  }
}
