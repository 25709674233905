import api from '../http/api';
import { ProposalOneFLowPersonalDataFormData } from '../screens/ProposalOneFLowPersonalData';

interface IPreValidationData extends ProposalOneFLowPersonalDataFormData {
  commercialGroupId: string;
  commercialOrigin: {
    id: string;
    name: string;
  };
  commercialOriginId: string;
  promoterId: string;
  productId: string;
}

interface IPrevalidationResponseData {
  commercial_origin_id: string;
  id: string;
  people_birthday: string;
  people_document: string;
  people_email: string;
  people_name: string;
  people_telephone_area: string;
  people_telephone_number: string;
  product_id: string;
  promoter_id: string;
  user_registry: string;
}

export default class SendDataToPreValidationService {
  public static async execute({
    commercialGroupId,
    commercialOrigin,
    commercialOriginId,
    peopleBirthday,
    peopleDocument,
    peopleEmail,
    peopleName,
    peopleTelephoneArea,
    peopleTelephoneNumber,
    productId,
    promoterId,
    userRegistry,
  }: IPreValidationData): Promise<IPrevalidationResponseData> {
    const { data } = await api.post<IPrevalidationResponseData>(
      `/self-attendance/pre-validation`,
      {
        commercial_group_id: commercialGroupId,
        commercial_origin: commercialOrigin,
        commercial_origin_id: commercialOriginId,
        people_birthday: peopleBirthday,
        people_document: peopleDocument,
        people_email: peopleEmail,
        people_telephone_area: peopleTelephoneArea,
        people_telephone_number: peopleTelephoneNumber,
        people_name: peopleName,
        user_registry: userRegistry,
        promoter_id: promoterId,
        product_id: productId,
      },
      {
        timeout: 10000,
        timeoutErrorMessage: 'Network Error',
      },
    );

    return data;
  }
}
