import React, { useState, useRef, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import SavePictureService from '../../services/SavePictureService';
import useDialog from '../../hooks/useDialog';
import Header from '../../components/atoms/Header';
import DigitalSignature from '../../components/molecules/DigitalSignature';
import errorHandling from '../../utils/errorHandling';
import ErrorConnect from '../../components/molecules/ErrorConnect';
import { ErrorConnectContainer } from './styles';
import useProposal from '../../hooks/useProposal';

const Signature = (): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const [errorConnect, setErrorConnect] = useState(false);
  const base64Ref = useRef({ value: '' });
  const { openDialog } = useDialog();
  const { state } = useLocation();
  const { proposal } = useProposal();
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const navigate = useNavigate();

  async function sendSignature() {
    try {
      setLoading(true);
      setButtonDisabled(true);
      await SavePictureService.execute({
        base64: base64Ref.current.value,
        proposalId: proposal.id,
        type: state.type,
        insuranceId: state.insurance_id,
      });

      navigate(state.next_route);
    } catch (error: any) {
      const { type, message } = errorHandling(error);

      if (type === 'ErrorConnect') {
        setErrorConnect(true);
        return;
      }

      setErrorConnect(false);
      openDialog({
        title: 'Ocorreu um erro no envio das informações',
        message,
        type: 'danger',
      });
    } finally {
      setLoading(false);
      setButtonDisabled(false);
    }
  }

  function prepareSignature(signature: string) {
    if (!signature) {
      openDialog({
        title: 'Aviso!',
        type: 'warning',
        message: 'A assinatura não pode estar em branco',
      });
      return;
    }

    base64Ref.current.value = signature;

    sendSignature();
  }

  useEffect(() => {
    window.scrollTo(0, 0);

    return () => setLoading(false);
  }, []);

  return (
    <>
      <Header />
      {!errorConnect ? (
        <div>
          <DigitalSignature
            buttonDisabled={buttonDisabled}
            onToSign={e => prepareSignature(e as string)}
            loading={loading}
          />
        </div>
      ) : (
        <ErrorConnectContainer>
          <ErrorConnect loading={loading} onClick={() => sendSignature()} />
        </ErrorConnectContainer>
      )}
    </>
  );
};

export default Signature;
