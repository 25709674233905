import React, { useEffect, useRef, useState } from 'react';
import Webcam from 'react-webcam';
import { useTheme } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import Paragraph from '../../components/atoms/Paragraph';
import Icon from '../../components/atoms/Icon';
import ButtonFilled from '../../components/atoms/ButtonFilled';
import useBiometryPicturePreview from '../../hooks/useBiometryPicturePreview';
import { Container, Header, Content, Overlay, Footer } from './styles';
import useProposal from '../../hooks/useProposal';

const BiometryPictureDocumentBack = (): JSX.Element => {
  const [cameraEnabled, setCameraEnabled] = useState(true);
  const theme = useTheme();
  const webcamRef = useRef<Webcam>(null);
  const { proposal } = useProposal();
  const navigate = useNavigate();
  const { openBiometryPicturePreview } = useBiometryPicturePreview();

  const restartCamera = () => {
    setCameraEnabled(false);
    setTimeout(() => setCameraEnabled(true), 700);
  };

  function takePhoto() {
    if (webcamRef.current) {
      const base64 = webcamRef.current.getScreenshot() || undefined;
      openBiometryPicturePreview({
        proposalId: proposal.id,
        base64,
        type: 'document_back',
        nextPage: 'prepare-picture-document-residence',
        message: 'A foto ficou boa? É possível ver nitidamente as informações do documento?',
      });
    }
  }

  useEffect(() => {
    window.addEventListener('focus', restartCamera);
    return () => {
      window.removeEventListener('focus', restartCamera);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container>
      <Header>
        <Icon
          onClick={() => navigate(-1)}
          size={40}
          color={theme.colors.white}
          name="FiChevronLeft"
        />
        <Paragraph color={theme.colors.white}>Enquadre o documento no espaço tracejado</Paragraph>
      </Header>
      <Content>
        <Webcam
          data-testid="webcam"
          ref={webcamRef}
          audio={false}
          videoConstraints={cameraEnabled ? { facingMode: 'environment' } : false}
          screenshotFormat="image/jpeg"
          screenshotQuality={1}
          forceScreenshotSourceSize
        />
        <Overlay>
          <svg
            width="100%"
            height="100%"
            className="svg"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
          >
            <defs>
              <mask id="overlay-mask" x="0" y="0" width="100%" height="100%">
                <rect x="0" y="0" width="100%" height="100%" fill="#fff" />

                <rect
                  stroke="#fff"
                  strokeWidth={3}
                  strokeDasharray={10}
                  strokeLinejoin="round"
                  x="50"
                  y="15%"
                  width="75%"
                  height="70%"
                />
              </mask>
            </defs>
            <rect
              x="0"
              y="0"
              width="100%"
              height="100%"
              mask="url(#overlay-mask)"
              fillOpacity="0.7"
            />
          </svg>
        </Overlay>
      </Content>
      <Footer>
        <ButtonFilled data-testid="buttonTakePhoto" onClick={() => takePhoto()}>
          Tirar foto do verso
        </ButtonFilled>
      </Footer>
    </Container>
  );
};

export default BiometryPictureDocumentBack;
