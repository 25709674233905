import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import Title from '../../components/atoms/Title';
import Header from '../../components/atoms/Header';
import NumberEntry from '../../components/atoms/NumberEntry';
import ButtonFilled from '../../components/atoms/ButtonFilled';
import Paragraph from '../../components/atoms/Paragraph';
import Icon from '../../components/atoms/Icon';
import useProposal from '../../hooks/useProposal';
import useDialog from '../../hooks/useDialog';
import ErrorConnect from '../../components/molecules/ErrorConnect';

import errorHandling from '../../utils/errorHandling';

import {
  View,
  ViewContent,
  ViewInput,
  ViewError,
  ViewLoading,
  ErrorConnectContainer,
} from './styles';

import SmsService from '../../services/SmsService';

type ErrorConnectState = {
  isError: boolean;
  callback?: () => Promise<void>;
};

const ProposalOneFlowSMSValidation = (): JSX.Element => {
  const seconds = 60;
  const timeRef = useRef<NodeJS.Timeout>();

  const [timeOut, setTimeOut] = useState(seconds);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisable, setIsDisable] = useState(true);
  const [isValidating, setIsValidating] = useState(false);
  const [isError, setIsError] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [errorConnect, setErrorConnect] = useState<ErrorConnectState>({} as ErrorConnectState);

  const { proposal } = useProposal();
  const { openDialog } = useDialog();
  const navigate = useNavigate();

  const inputRefFirst = useRef<HTMLInputElement>(null);
  const inputRefSecond = useRef<HTMLInputElement>(null);
  const inputRefThird = useRef<HTMLInputElement>(null);
  const inputRefFourth = useRef<HTMLInputElement>(null);
  const inputRefFifth = useRef<HTMLInputElement>(null);
  const inputRefSixth = useRef<HTMLInputElement>(null);

  const chronometer = useCallback(() => {
    let interval = seconds;

    setIsDisable(true);

    timeRef.current = global.setInterval(() => {
      setTimeOut(interval - 1);
      interval -= 1;
      if (interval === 0 && timeRef.current) {
        clearInterval(timeRef.current);
        setIsDisable(false);
      }
    }, 1000);
  }, []);

  function clearInputs() {
    if (
      inputRefFirst.current &&
      inputRefSecond.current &&
      inputRefThird.current &&
      inputRefFourth.current &&
      inputRefFifth.current &&
      inputRefSixth.current
    ) {
      inputRefFirst.current.value = '';
      inputRefSecond.current.value = '';
      inputRefThird.current.value = '';
      inputRefFourth.current.value = '';
      inputRefFifth.current.value = '';
      inputRefSixth.current.value = '';

      inputRefFirst.current.focus();
    }
  }

  function handlingHttpErrors(error: any, fn: () => Promise<void>) {
    const { type, message } = errorHandling(error);

    if (type === 'ErrorConnect') {
      setErrorConnect({
        isError: true,
        callback: fn,
      });
      return;
    }

    setErrorConnect({
      isError: false,
    });

    openDialog({
      title: 'Ocorreu um erro no envio das informações',
      message,
      type: 'danger',
    });
  }

  async function sendSmSCodeToValidate() {
    const code =
      (inputRefFirst.current?.value as string) +
      (inputRefSecond.current?.value as string) +
      inputRefThird.current?.value +
      inputRefFourth.current?.value +
      inputRefFifth.current?.value +
      inputRefSixth.current?.value;

    try {
      setIsError(false);
      setIsValidating(true);
      const { id } = proposal;
      await SmsService.validationSms({
        id,
        code,
      });
      navigate('/proposal-two-flow-personal-data');
    } catch (error: any) {
      if (error.response?.data.message === 'Não foi possível validar o código') {
        setErrorConnect({
          isError: false,
        });

        setIsError(true);
        clearInputs();
        inputRefFirst.current?.focus();
      } else {
        handlingHttpErrors(error, sendSmSCodeToValidate);
      }
    } finally {
      setIsValidating(false);
    }
  }

  async function resendSMS() {
    try {
      setIsError(false);
      setIsLoading(true);
      setButtonDisabled(true);
      setErrorConnect({ isError: false });
      await SmsService.requestSms(proposal.id);
      clearInputs();
      chronometer();
      inputRefFirst.current?.focus();
    } catch (error: any) {
      handlingHttpErrors(error, resendSMS);
    } finally {
      setIsLoading(false);
      setButtonDisabled(false);
      setIsValidating(false);
    }
  }

  useEffect(() => {
    chronometer();

    return () => {
      if (!timeRef.current) {
        return;
      }

      clearInterval(timeRef.current);
    };
  }, [chronometer]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <View>
      <Header />
      <ViewContent data-testid="ProposalOneFlowSMSValidation">
        <Title fontSize={20}>Digite abaixo o código SMS que </Title>
        <Title fontSize={20}>
          enviamos para ({proposal.people_telephone_area}) {proposal.people_telephone_number}
        </Title>
        <ViewInput>
          <NumberEntry
            maxLength={1}
            width={60}
            ref={inputRefFirst}
            onChange={value => value && inputRefSecond.current?.focus()}
            autoFocus
            error={{
              state: isError,
            }}
          />
          <NumberEntry
            maxLength={1}
            width={60}
            ref={inputRefSecond}
            onChange={value =>
              value ? inputRefThird.current?.focus() : inputRefFirst.current?.focus()
            }
            error={{
              state: isError,
            }}
          />
          <NumberEntry
            maxLength={1}
            width={60}
            onChange={value =>
              value ? inputRefFourth.current?.focus() : inputRefSecond.current?.focus()
            }
            ref={inputRefThird}
            error={{
              state: isError,
            }}
          />
          <NumberEntry
            maxLength={1}
            width={60}
            onChange={value =>
              value ? inputRefFifth.current?.focus() : inputRefThird.current?.focus()
            }
            ref={inputRefFourth}
            error={{
              state: isError,
            }}
          />
          <NumberEntry
            maxLength={1}
            width={60}
            onChange={value =>
              value ? inputRefSixth.current?.focus() : inputRefFourth.current?.focus()
            }
            ref={inputRefFifth}
            error={{
              state: isError,
            }}
          />
          <NumberEntry
            maxLength={1}
            width={60}
            onChange={value => (value ? sendSmSCodeToValidate() : inputRefFifth.current?.focus())}
            ref={inputRefSixth}
            error={{
              state: isError,
            }}
          />
        </ViewInput>
        <ViewError>{isError && <Paragraph color="red">Código Inválido</Paragraph>}</ViewError>
        {!isValidating && (
          <ViewLoading>
            <Icon name="FiClock" size={30} />
            <Paragraph>
              Não recebeu o código? Solicite novamente{' '}
              {timeOut === 0 ? '' : `em ${timeOut} segundos`}
            </Paragraph>
          </ViewLoading>
        )}
        {isValidating && (
          <ViewLoading>
            <Icon name="FiRefreshCcw" />
            <Paragraph>Validando...</Paragraph>
          </ViewLoading>
        )}
        <ButtonFilled
          disabled={isDisable || buttonDisabled}
          loading={isLoading}
          onClick={() => resendSMS()}
        >
          Solicitar código novamente
        </ButtonFilled>

        {errorConnect.isError && (
          <ErrorConnectContainer>
            <ErrorConnect
              loading={isLoading || isValidating}
              onClick={() => (errorConnect.callback ? errorConnect.callback() : undefined)}
            />
          </ErrorConnectContainer>
        )}
      </ViewContent>
    </View>
  );
};

export default ProposalOneFlowSMSValidation;
