import styled from 'styled-components';

export const View = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5px;

  h1 {
    margin-left: 22px;
  }

  iframe {
    align-self: center;
    margin-top: 30px;
    width: 95%;
    height: 70vh;

    @media (max-width: 320px) {
      width: 88%;
    }
  }

  button {
    &:last-child {
      @media (max-width: 379px) {
        width: 95%;
      }
    }
  }
`;
