import styled from 'styled-components';

export const Container = styled.div`
  @media (max-width: 375px) {
    button {
      width: 260px;
    }
  }
`;

export const Content = styled.main`
  padding: 0 25px;
`;

export const Form = styled.form`
  margin-top: 20px;
  div {
    margin-bottom: 10px;
  }
`;

export const ErrorConnectContainer = styled.div`
  width: 100vw;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;
`;
