import React from 'react';
import { useTheme } from 'styled-components';

import Icon from '../../atoms/Icon';
import Title from '../../atoms/Title';
import Paragraph from '../../atoms/Paragraph';
import IconWithRoundBackground from '../IconWithRoundBackground';
import ButtonFilled from '../../atoms/ButtonFilled';
import ButtonOutline from '../../atoms/ButtonOutline';
import { DialogData } from '../../../contexts/Dialog';
import { Overlay, Container, GroupButtons } from './styles';

type DialogProps = DialogData & {
  close: () => void;

  style?: {
    width?: number;
    height?: number;
  };
};

type DialogIconProps = {
  [key: string]: {
    backgroundColor: string;
    iconName: 'FiCheck' | 'FiAlertTriangle' | 'FiX' | 'FiAlertCircle';
    size: number;
  };
};

export default function Dialog({
  title,
  message,
  type,
  buttonPrimary,
  buttonSecondary,
  disableCloseByOverlay,
  close,
  style,
}: DialogProps): JSX.Element {
  const {
    colors: { gray400, black, success, white, warning, danger, primary },
  } = useTheme();

  const hasMultipleButtons = !!buttonPrimary && !!buttonSecondary;

  const dialogIconProps: DialogIconProps = {
    success: {
      backgroundColor: success,
      iconName: 'FiCheck',
      size: 35,
    },
    warning: {
      backgroundColor: warning,
      iconName: 'FiAlertTriangle',
      size: 34,
    },
    danger: {
      backgroundColor: danger,
      iconName: 'FiX',
      size: 35,
    },
    info: {
      backgroundColor: primary,
      iconName: 'FiAlertCircle',
      size: 35,
    },
  };

  return (
    <Overlay onClick={disableCloseByOverlay ? undefined : close}>
      <Container
        onClick={e => e.stopPropagation()}
        data-testid="DialogContainer"
        width={style?.width}
        height={style?.height}
      >
        <button type="button" onClick={close}>
          <Icon name="FiX" color={gray400} size={28} />
        </button>

        <main>
          <IconWithRoundBackground
            width={60}
            heigth={60}
            backgroundColor={dialogIconProps[type].backgroundColor}
            icon={{
              name: dialogIconProps[type].iconName,
              color: white,
              size: dialogIconProps[type].size,
            }}
          />

          <Title color={black}>{title}</Title>

          {Array.isArray(message) ? (
            <ul>
              {message.map((text, index) => (
                <li key={String(index)}>{text}</li>
              ))}
            </ul>
          ) : (
            <Paragraph>{message}</Paragraph>
          )}

          {(buttonPrimary || buttonSecondary) && (
            <GroupButtons hasMultipleButtons={hasMultipleButtons}>
              {buttonSecondary && (
                <ButtonOutline
                  onClick={buttonSecondary.onClick}
                  width={hasMultipleButtons ? 150 : 250}
                >
                  {buttonSecondary.text}
                </ButtonOutline>
              )}

              {buttonPrimary && (
                <ButtonFilled
                  onClick={buttonPrimary.onClick}
                  width={hasMultipleButtons ? 150 : 250}
                >
                  {buttonPrimary.text}
                </ButtonFilled>
              )}
            </GroupButtons>
          )}
        </main>
      </Container>
    </Overlay>
  );
}
