import React from 'react';
import { useTheme } from 'styled-components';
import CreditCard from '../../../assets/credit-card.png';
import IconWithRoundBackground from '../../molecules/IconWithRoundBackground';
import Title from '../../atoms/Title';
import Paragraph from '../../atoms/Paragraph';
import { Container, Content } from './styles';
import useApplicationConfig from '../../../hooks/useApplicationConfig';
import { IconType } from '../../../models/Icons';

type ProposalResultProps = {
  decision: 'APPROVED' | 'PENDENT';
};

type ResultTypeProps = {
  [key: string]: {
    title: string;
    body: string;
    icon: IconType;
    color: string;
  };
};

const ProposalResult = ({ decision }: ProposalResultProps): JSX.Element => {
  const { colors } = useTheme();
  const { applicationConfig } = useApplicationConfig();
  const result: ResultTypeProps = {
    APPROVED: {
      title: 'Sua proposta foi aprovada',
      body: applicationConfig.approved_message,
      icon: 'FiCheck',
      color: colors.success,
    },
    PENDENT: {
      title: 'Sua proposta foi enviada para análise',
      body: 'Sua proposta foi enviada para análise, em breve daremos um retorno',
      icon: 'FiAlertCircle',
      color: colors.warning,
    },
  };
  return (
    <Container data-testid="proposalResult">
      <IconWithRoundBackground
        backgroundColor={result[decision].color}
        width={80}
        heigth={80}
        icon={{ name: result[decision].icon, color: colors.white, size: 50 }}
      />
      <img src={CreditCard} alt="credit-card" />
      <Content>
        <Title>{result[decision].title}</Title>
        <br />
        <Paragraph>{result[decision].body}</Paragraph>
      </Content>
    </Container>
  );
};

export default ProposalResult;
