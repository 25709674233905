import React, { useState, InputHTMLAttributes } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { useTheme } from 'styled-components';
import { zipCodeMask } from '../../../utils/masks';
import { Container, Content } from './styles';

type ZipCodeEntryProps = InputHTMLAttributes<HTMLInputElement> & {
  onChange: (text: string) => void;
  error?: string;
  loading?: boolean;
};

const ZipCodeEntry = ({ onChange, error, loading, ...rest }: ZipCodeEntryProps): JSX.Element => {
  const { colors } = useTheme();
  const [isFocused, setIsFocused] = useState(false);
  return (
    <Container>
      <Content data-testid="ZipCodeEntryContent" isErrored={!!error} isFocused={isFocused}>
        <input
          data-testid="ZipCodeEntry"
          placeholder="CEP"
          type="text"
          onChange={e => {
            onChange(zipCodeMask(e.target.value));
          }}
          maxLength={9}
          inputMode="numeric"
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          {...rest}
        />
        {loading && (
          <LoadingOutlined data-testid="Loading" style={{ fontSize: 25, color: colors.gray300 }} />
        )}
      </Content>
      {error && <span>{error}</span>}
    </Container>
  );
};

export default ZipCodeEntry;
