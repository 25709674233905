import React, { useCallback, useState } from 'react';

import getImageUrl from '../../utils/getImageUrl';

export interface IApplicationConfigData {
  approved_message: string;
  background_image_url: string;
  commercial_group_id: string;
  commercial_origin_id: string;
  domain: string;
  id: string;
  logo_url: string;
  politic_and_terms_url: string;
  product_id: string;
  promoter_id: string;
  rejected_message: string;
  welcome_message: string;
  theme_name: string;
  commercial_origin: {
    id: string;
    name: string;
  };
  product: {
    commercial_group_id: string;
    external_code: string;
    id: string;
    image: string;
    title: string;
    type: string;
  };
}

type ApplicationConfigProps = {
  children: React.ReactNode;
};

export type ApplicationConfigData = {
  applicationConfig: IApplicationConfigData;
  setApplicationConfig: (data: IApplicationConfigData) => void;
};

export const ApplicationContext = React.createContext<ApplicationConfigData>(
  {} as ApplicationConfigData,
);

const ApplicationConfigProvider = ({ children }: ApplicationConfigProps): JSX.Element => {
  const [applicationConfigData, setApplicationConfigData] = useState<IApplicationConfigData>(
    {} as IApplicationConfigData,
  );

  const setApplicationConfig = useCallback((data: IApplicationConfigData) => {
    setApplicationConfigData({
      ...data,
      background_image_url: getImageUrl(data.background_image_url, 'logos'),
      logo_url: getImageUrl(data.logo_url, 'logos'),
      product: {
        ...data.product,
        image: getImageUrl(data.product.image, 'products'),
      },
    });
  }, []);

  return (
    <ApplicationContext.Provider
      value={{
        applicationConfig: applicationConfigData,
        setApplicationConfig,
      }}
    >
      {children}
    </ApplicationContext.Provider>
  );
};

export default ApplicationConfigProvider;
