import styled, { css } from 'styled-components';

type ContentProps = {
  isFocused: boolean;
  isErrored: boolean;
  width?: number;
};

export const Container = styled.div`
  span {
    margin-left: 1px;

    font-size: 14px;
    font-family: ${({ theme: { fonts } }) => fonts.paragraph.family};
    color: ${({ theme: { colors } }) => colors.danger};
  }
`;

export const Content = styled.div<ContentProps>`
  ${props =>
    props.width
      ? css`
          width: ${props.width}px;
        `
      : css`
          width: 100%;
        `};
  height: 65px;

  border-radius: 10px;
  padding: 5px 10px;

  ${({ isFocused, theme: { colors } }) =>
    isFocused &&
    css`
      border: 1.5px solid ${colors.primary};
    `};

  ${({ isErrored, theme: { colors } }) =>
    isErrored &&
    css`
      border: 1.5px solid ${colors.danger};
    `};

  background-color: ${({ theme: { colors } }) => colors.white100};

  input {
    width: 100%;
    height: 95%;

    background-color: transparent;
    border: 0;
    outline: none;

    font-size: 18px;
    font-family: ${({ theme: { fonts } }) => fonts.paragraph.family};
    color: ${({ theme: { colors } }) => colors.gray400};
  }

  input::placeholder {
    color: ${({ theme: { colors } }) => colors.gray200};
  }
`;
