import api from '../http/api';
import { IOccupation } from '../models/Occupation';

export default class GetOccupationsService {
  public static async execute(): Promise<IOccupation[]> {
    const { data } = await api.get<IOccupation[]>(`self-attendance/occupations`);

    return data;
  }
}
