import styled, { css } from 'styled-components';

type ContentProps = {
  isFocused: boolean;
  isErrored: boolean;
};

export const Container = styled.div`
  span {
    margin-left: 5px;
    font-size: 14px;
    font-family: ${props => props.theme.fonts.paragraph.family};
    color: ${$props => $props.theme.colors.danger};
  }
`;

export const Content = styled.div<ContentProps>`
  display: flex;
  width: 100%;
  height: 65px;
  align-items: center;
  padding-right: 20px;
  border-radius: 10px;
  background-color: ${props => props.theme.colors.white100};

  ${({ isFocused, theme }) =>
    isFocused &&
    css`
      border: 1.5px solid ${theme.colors.primary};
    `};
  ${({ isErrored, theme }) =>
    isErrored &&
    css`
      border: 1.5px solid ${theme.colors.danger};
    `};

  input {
    width: 100%;
    height: 95%;
    border: 0;
    padding: 10px;
    font-size: 18px;
    outline: none;
    background-color: transparent;
    font-family: ${props => props.theme.fonts.paragraph.family};
    color: ${props => props.theme.colors.gray400};
  }

  input::placeholder {
    color: ${({ theme: { colors } }) => colors.gray200};
  }
`;
