import api from '../http/api';

import { IApplicationConfigData } from '../contexts/ApplicationConfig';

export default class ApplicationConfigService {
  public static async execute(): Promise<IApplicationConfigData> {
    const { data } = await api.get<IApplicationConfigData>(`/self-attendance/config`, {
      timeout: 3000,
      timeoutErrorMessage: 'Network Error',
    });

    return data;
  }
}
