import React, { createContext, useCallback, useState } from 'react';

import SendFullValidationService from '../../services/SendFullValidationService';
import { Proposal } from '../../models/Proposal';

type ProposalProviderProps = {
  children: React.ReactNode;
};

export type ProposalContextData = {
  proposal: Proposal;
  setProposal: (data: Proposal) => void;
  stepCompleted: number;
  setStepCompleted: (stepCompleted: number) => void;
  sendFullValidation: () => Promise<void>;
  clearProposalData: () => void;
};

export const ProposalContext = createContext<ProposalContextData>({} as ProposalContextData);

export default function ProposalProvider({ children }: ProposalProviderProps): JSX.Element {
  const [proposalData, setProposalData] = useState<Proposal>({} as Proposal);
  const [currentStepCompleted, setCurrentStepCompleted] = useState(1);

  function setProposal(data: Proposal) {
    setProposalData(currentState => ({
      ...currentState,
      ...data,
    }));
  }

  function setStepCompleted(stepCompleted: number) {
    setCurrentStepCompleted(stepCompleted);
  }

  async function sendFullValidation() {
    const { origin_receiving_card_name, ...proposalToSend } = proposalData;
    const response = await SendFullValidationService.execute(proposalToSend);

    setProposalData(currentState => ({
      ...currentState,
      ...response,
    }));
  }

  const clearProposalData = useCallback(() => {
    setProposalData({} as Proposal);
  }, []);

  return (
    <ProposalContext.Provider
      value={{
        proposal: proposalData,
        setProposal,
        stepCompleted: currentStepCompleted,
        setStepCompleted,
        sendFullValidation,
        clearProposalData,
      }}
    >
      {children}
    </ProposalContext.Provider>
  );
}
