import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { View } from './styles';
import Title from '../../components/atoms/Title';
import TermsOfContract from '../../components/molecules/TermsOfContract';
import ButtonFilled from '../../components/atoms/ButtonFilled';
import FixedToBottom from '../../components/molecules/FixedToBottom';
import Header from '../../components/atoms/Header';
import useProposal from '../../hooks/useProposal';
import getTermsUrl from '../../utils/getTermsUrl';
import SendPictureProps from '../../models/SendPictureProps';

const CreditCardTerm = (): JSX.Element => {
  const navigate = useNavigate();
  const { proposal } = useProposal();
  const creditCardTermUrl = getTermsUrl(proposal.id, 'tac');

  const props: SendPictureProps = {
    type: 'tac',
    next_route: '/lgpd-term',
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <View data-testid="creditcardterm">
      <Header />
      <Title>Termo de adesão do Cartão</Title>
      <TermsOfContract src={creditCardTermUrl} />
      <FixedToBottom>
        <ButtonFilled
          onClick={() =>
            navigate('/digital-signature', {
              state: props,
            })
          }
        >
          Concordar e assinar
        </ButtonFilled>
      </FixedToBottom>
    </View>
  );
};

export default CreditCardTerm;
