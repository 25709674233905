import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ButtonFilled from '../../components/atoms/ButtonFilled';
import Header from '../../components/atoms/Header';
import Icon from '../../components/atoms/Icon';
import Paragraph from '../../components/atoms/Paragraph';
import Title from '../../components/atoms/Title';
import FixedToBottom from '../../components/molecules/FixedToBottom';
import { Container, Content } from './style';

const TutorialCamera = (): JSX.Element => {
  const navigate = useNavigate();
  const releaseCamera = async () => {
    try {
      await navigator.mediaDevices.getUserMedia({ audio: false, video: true });
      navigate('/prepare-picture-selfie');
    } catch (err) {
      navigate('/camera-disabled');
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container>
      <Header />
      <Content>
        <Icon name="FiCamera" size={70} strokeWidth={1.2} />
        <Title>Permita o acesso à câmera do celular</Title>
        <Paragraph>
          O acesso à câmera do seu celular será necessário pois a foto será usada na sua
          identificação
        </Paragraph>
      </Content>
      <FixedToBottom className="fixed">
        <ButtonFilled onClick={() => releaseCamera()}>Continuar</ButtonFilled>
      </FixedToBottom>
    </Container>
  );
};

export default TutorialCamera;
