import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ButtonFilled from '../../components/atoms/ButtonFilled';
import Header from '../../components/atoms/Header';
import Icon from '../../components/atoms/Icon';
import Title from '../../components/atoms/Title';
import FixedToBottom from '../../components/molecules/FixedToBottom';
import StepList from '../../components/organisms/StepList';
import { Container, Content } from './style';

export const steps = {
  Android: [
    'Localize as configurações do seu navegador',
    'Clique em permissões ou configurações do site',
    'Clique em câmera para permitir o seu uso.',
  ],
  Iphone: [
    'Abra o Ajustes',
    'Localize seu navegador de preferência',
    'Clique no navegador',
    'Permita o uso da câmera',
  ],
};

const CameraDisabled = (): JSX.Element => {
  const navigate = useNavigate();

  const toDetectOs = () => {
    const agent = navigator.userAgent;
    if (agent.includes('Android') || agent.includes('win32') || agent.includes('linux')) {
      return 'Android';
    }
    return 'Iphone';
  };

  const checkPermssionsCamera = async () => {
    try {
      await navigator.mediaDevices.getUserMedia({ audio: false, video: true });
      navigate('/prepare-picture-selfie');
    } catch (err: any) {
      navigate('/tutorial-camera');
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container>
      <Header />
      <Content>
        <Icon name="FiCameraOff" size={70} strokeWidth={1.2} />
        <Title>É necessário habilitar o acesso à camera para continuar</Title>
        <StepList data={steps[toDetectOs()]} />
      </Content>
      <FixedToBottom>
        <ButtonFilled onClick={() => checkPermssionsCamera()}>Continuar</ButtonFilled>
      </FixedToBottom>
    </Container>
  );
};

export default CameraDisabled;
