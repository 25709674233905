import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { View } from './styles';
import Title from '../../components/atoms/Title';
import TermsOfContract from '../../components/molecules/TermsOfContract';
import ButtonFilled from '../../components/atoms/ButtonFilled';
import FixedToBottom from '../../components/molecules/FixedToBottom';
import Header from '../../components/atoms/Header';
import useProposal from '../../hooks/useProposal';
import getTermsUrl from '../../utils/getTermsUrl';
import SendPictureProps from '../../models/SendPictureProps';

const LGPDTerm = (): JSX.Element => {
  const { proposal } = useProposal();
  const lgdpTermUrl = getTermsUrl(proposal.id, 'lgpd');
  const navigate = useNavigate();

  const props: SendPictureProps = {
    type: 'lgpd',
    next_route: '/contract-insurances',
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <View data-testid="lgpdterm">
      <Header />
      <Title>Termo de adesão LGPD</Title>
      <TermsOfContract src={lgdpTermUrl} />
      <FixedToBottom>
        <ButtonFilled
          onClick={() =>
            navigate('/digital-signature', {
              state: props,
            })
          }
        >
          Concordar e assinar
        </ButtonFilled>
      </FixedToBottom>
    </View>
  );
};

export default LGPDTerm;
