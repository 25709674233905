import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  width: 100%;
  padding: 5px 0;
  background-color: #fff;

  &:active {
    opacity: 0.6;
  }
`;
