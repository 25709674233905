import apiZipCode from '../http/apiZipCodeProvider';

type ZipCodeResponseData = {
  cep: string;
  state: string;
  city: string;
  neighborhood: string;
  street: string;
};

export default class ZipCodeService {
  public static async findZipCodeByNumber(zipCode: string): Promise<ZipCodeResponseData> {
    const { data } = await apiZipCode.get<ZipCodeResponseData>(`/${zipCode}`);
    return data;
  }
}
