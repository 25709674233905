import React, { InputHTMLAttributes, useState } from 'react';

import { dateMask } from '../../../utils/masks';
import { Container, Content } from './styles';

type DateEntryProps = InputHTMLAttributes<HTMLInputElement> & {
  onChange: (text: string) => void;
  error?: string;
};

export default function DateEntry({ onChange, error, ...rest }: DateEntryProps): JSX.Element {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <Container>
      <Content data-testid="DateEntryContainer" isFocused={isFocused} isErrored={!!error}>
        <input
          data-testid="DateEntryInput"
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          type="text"
          onChange={e => {
            onChange(dateMask(e.target.value));
          }}
          maxLength={10}
          inputMode="numeric"
          {...rest}
        />
      </Content>
      {error && <span>{error}</span>}
    </Container>
  );
}
