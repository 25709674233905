import React, { createContext, ReactNode, useCallback, useState } from 'react';

import Portal from '../../components/organisms/Portal';
import Dialog from '../../components/molecules/Dialog';

export type DialogData = {
  title: string;
  message: string | string[];
  type: 'success' | 'danger' | 'info' | 'warning';
  disableCloseByOverlay?: boolean;

  buttonPrimary?: {
    text: string;
    onClick: () => void;
  };
  buttonSecondary?: {
    text: string;
    onClick: () => void;
  };
};

export type DialogContextData = {
  openDialog: (data: DialogData) => void;
  closeDialog: () => void;
  isVisible: boolean;
};

type DialogProviderProps = {
  children: ReactNode;
};

export const DialogContext = createContext<DialogContextData>({} as DialogContextData);

export default function DialogProvider({ children }: DialogProviderProps): JSX.Element {
  const [visible, setVisible] = useState(false);
  const [dialogData, setDialogData] = useState({} as DialogData);

  const openDialog = useCallback(
    ({
      message,
      title,
      type,
      buttonPrimary,
      buttonSecondary,
      disableCloseByOverlay,
    }: DialogData) => {
      setDialogData({
        message,
        title,
        type,
        buttonPrimary,
        buttonSecondary,
        disableCloseByOverlay,
      });

      setVisible(true);
    },
    [],
  );

  const closeDialog = useCallback(() => setVisible(false), []);

  return (
    <DialogContext.Provider value={{ openDialog, closeDialog, isVisible: visible }}>
      {children}

      {visible && (
        <Portal>
          <Dialog
            title={dialogData.title}
            message={dialogData.message}
            type={dialogData.type}
            buttonPrimary={dialogData.buttonPrimary}
            buttonSecondary={dialogData.buttonSecondary}
            disableCloseByOverlay={dialogData.disableCloseByOverlay}
            close={closeDialog}
          />
        </Portal>
      )}
    </DialogContext.Provider>
  );
}
