import React, { useRef } from 'react';
import SignaturePad from 'react-signature-pad-wrapper';
import { useTheme } from 'styled-components';
import ButtonFilled from '../../atoms/ButtonFilled';
import { ViewDigitalSignature, ViewButtons, Container } from './styles';

type DigitalSignatureProps = {
  onToSign: (e?: string) => void;
  loading: boolean;
  buttonDisabled: boolean;
};

const DigitalSignature = ({
  onToSign,
  loading,
  buttonDisabled,
}: DigitalSignatureProps): JSX.Element => {
  const { colors } = useTheme();
  const signRef = useRef<SignaturePad>(null);
  const clear = () => signRef.current?.clear();

  const captureSignature = () => {
    if (signRef.current?.isEmpty()) {
      return undefined;
    }
    return signRef.current?.toDataURL('image/png');
  };

  return (
    <Container>
      <ViewDigitalSignature>
        <SignaturePad ref={signRef} height={300} />
      </ViewDigitalSignature>
      <ViewButtons>
        <ButtonFilled
          onClick={clear}
          backgroundColor={colors.gray100}
          data-testid="buttonClearSignature"
        >
          Limpar
        </ButtonFilled>
        <ButtonFilled
          backgroundColor={colors.success}
          data-testid="buttonCaptureSignature"
          loading={loading}
          onClick={() => onToSign(captureSignature())}
          disabled={buttonDisabled}
        >
          Avançar
        </ButtonFilled>
      </ViewButtons>
    </Container>
  );
};

export default DigitalSignature;
