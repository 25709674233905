import React from 'react';
import { useTheme } from 'styled-components';
import { LoadingOutlined } from '@ant-design/icons';
import { ButtonProps } from '../../../models/Button';

import { Button } from './styles';

type ButtonFilledProps = ButtonProps & {
  backgroundColor?: string;
};

export default function ButtonFilled({
  children,
  width,
  height,
  backgroundColor,
  disabled,
  loading,
  ...rest
}: ButtonFilledProps): JSX.Element {
  const { colors } = useTheme();
  return (
    <Button
      data-testid="ButtonFilledContainer"
      backgroundColor={backgroundColor}
      width={width}
      height={height}
      disabled={disabled}
      {...rest}
    >
      {loading ? (
        <LoadingOutlined data-testid="Loading" style={{ fontSize: 25, color: colors.white100 }} />
      ) : (
        children
      )}
    </Button>
  );
}
