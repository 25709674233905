import React, { useState, useEffect, useCallback, useRef } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

import Icon from '../../components/atoms/Icon';
import FixedToBottom from '../../components/molecules/FixedToBottom';
import ButtonFilled from '../../components/atoms/ButtonFilled';
import ButtonLink from '../../components/atoms/ButtonLink';
import Header from '../../components/atoms/Header';
import Title from '../../components/atoms/Title';
import InsuranceItem from '../../components/molecules/InsuranceItem';
import { IInsurance } from '../../models/Insurance';
import GetInsurancesService from '../../services/GetInsurancesService';
import RemoveInsuranceContractService from '../../services/RemoveInsuranceContractService';
import { Container, Content, List, ErrorConnectContainer, ListItem } from './styles';
import ErrorConnect from '../../components/molecules/ErrorConnect';
import errorHandling from '../../utils/errorHandling';
import useDialog from '../../hooks/useDialog';
import useProposal from '../../hooks/useProposal';
import GlobalLoading from '../../components/atoms/GlobalLoading';

type ErrorConnectState = {
  isError: boolean;
  callback?: () => Promise<void>;
};

export default function ContractInsurances(): JSX.Element {
  const [insurances, setInsurances] = useState<IInsurance[]>([]);
  const [getInsurancesLoading, setGetInsurancesLoading] = useState(false);
  const [removeInsuranceContractLoading, setRemoveInsuranceContractLoading] = useState(false);
  const [sendProposalLoading, setSendProposalLoading] = useState(false);
  const [errorConnect, setErrorConnect] = useState<ErrorConnectState>({} as ErrorConnectState);

  const navigate = useNavigate();
  const { openDialog, closeDialog } = useDialog();
  const { proposal, sendFullValidation } = useProposal();

  const allInsurancesNotHiredRef = useRef({ value: true });
  const removalProposalInsuranceIdRef = useRef({ value: '0' });
  const proposalIdRef = useRef({ value: proposal.id });

  function verifyIfHiredInsurancesExist(insurancesData: IInsurance[]) {
    insurancesData.forEach(({ proposal_insurance }) => {
      allInsurancesNotHiredRef.current.value = !proposal_insurance?.id;
    });
  }

  const handlingHttpErrors = useCallback(
    (error: any, fn: () => Promise<void>) => {
      const { type, message } = errorHandling(error);

      if (type === 'ErrorConnect') {
        setErrorConnect({
          isError: true,
          callback: fn,
        });

        return;
      }

      setErrorConnect({
        isError: false,
      });
      openDialog({
        title: 'Ocorreu um erro no envio das informações',
        message,
        type: 'danger',
      });
    },
    [openDialog],
  );

  const getInsurances = useCallback(async () => {
    try {
      setGetInsurancesLoading(true);
      const response = await GetInsurancesService.execute(proposalIdRef.current.value);

      setInsurances(response);
      verifyIfHiredInsurancesExist(response);

      setErrorConnect({
        isError: false,
      });
    } catch (error: any) {
      handlingHttpErrors(error, getInsurances);
    } finally {
      setGetInsurancesLoading(false);
    }
  }, [handlingHttpErrors]);

  async function handleRemoveHiring() {
    try {
      setRemoveInsuranceContractLoading(true);
      await RemoveInsuranceContractService.execute(removalProposalInsuranceIdRef.current.value);

      getInsurances();

      setErrorConnect({
        isError: false,
      });
    } catch (error: any) {
      handlingHttpErrors(error, handleRemoveHiring);
    } finally {
      setRemoveInsuranceContractLoading(false);
    }
  }

  async function handleSendProposal() {
    try {
      setSendProposalLoading(true);
      await sendFullValidation();

      navigate('/proposal-result');
    } catch (error: any) {
      // handlingHttpErrors(error, handleSendProposal);
      navigate('/proposal-result');
    } finally {
      setSendProposalLoading(false);
    }
  }

  useEffect(() => {
    getInsurances();
  }, [getInsurances]);

  useEffect(() => {
    window.scrollTo(0, 0);

    return () => setSendProposalLoading(false);
  }, []);

  return (
    <Container>
      <Header />

      {(removeInsuranceContractLoading || sendProposalLoading) && !errorConnect.isError && (
        <GlobalLoading backgroundColor="rgba(0, 0, 0, 0.12)" />
      )}

      {!errorConnect.isError ? (
        <>
          <Content>
            <Title data-testid="TitleContractInsurances">
              Adicione nossos seguros e proteja-se
            </Title>

            {getInsurancesLoading ? (
              <div>
                <LoadingOutlined />
              </div>
            ) : (
              <List data-testid="ListInsurances">
                {insurances.map(({ id, description, proposal_insurance, document }) => (
                  <ListItem key={id}>
                    <InsuranceItem
                      data-testid="InsuranceItemListing"
                      name={description}
                      isHired={!!proposal_insurance?.id}
                      onClick={() =>
                        navigate('/insurance-term', {
                          state: {
                            slug: document?.slug,
                            insuranceId: id,
                          },
                        })
                      }
                    />

                    {!!proposal_insurance && (
                      <button
                        onClick={() =>
                          openDialog({
                            title: 'Tem certeza que quer remover a contratação deste seguro?',
                            message: 'Você ainda poderá contratá-lo novamente se quiser',
                            type: 'warning',
                            buttonPrimary: {
                              onClick: closeDialog,
                              text: 'Cancelar',
                            },
                            buttonSecondary: {
                              onClick: () => {
                                closeDialog();

                                removalProposalInsuranceIdRef.current.value = proposal_insurance.id;
                                handleRemoveHiring();
                              },
                              text: 'Remover',
                            },
                          })
                        }
                        type="button"
                      >
                        <Icon name="FiTrash2" />
                      </button>
                    )}
                  </ListItem>
                ))}
              </List>
            )}
          </Content>

          <FixedToBottom>
            <ButtonFilled
              disabled={allInsurancesNotHiredRef.current.value}
              onClick={() => handleSendProposal()}
              style={{ marginBottom: 10 }}
            >
              Contratar e finalizar
            </ButtonFilled>

            {allInsurancesNotHiredRef.current.value && (
              <ButtonLink style={{ marginBottom: 10 }} onClick={() => handleSendProposal()}>
                Não quero seguro
              </ButtonLink>
            )}
          </FixedToBottom>
        </>
      ) : (
        <ErrorConnectContainer>
          <ErrorConnect
            onClick={() => (errorConnect.callback ? errorConnect.callback() : undefined)}
            loading={getInsurancesLoading || removeInsuranceContractLoading || sendProposalLoading}
          />
        </ErrorConnectContainer>
      )}
    </Container>
  );
}
