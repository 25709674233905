import React, { useEffect } from 'react';
import { Container } from './styles';
import ProposalResult from '../../components/organisms/ProposalResult';
import useProposal from '../../hooks/useProposal';

const ProposalScreenResult = (): JSX.Element => {
  const { proposal, clearProposalData } = useProposal();

  useEffect(() => {
    window.scrollTo(0, 0);

    return () => clearProposalData();
  }, [clearProposalData]);

  return (
    <Container data-testid="ProposalScreenResult">
      {proposal?.decision === 'APPROVED' ? (
        <ProposalResult decision="APPROVED" />
      ) : (
        <ProposalResult decision="PENDENT" />
      )}
    </Container>
  );
};

export default ProposalScreenResult;
