import styled from 'styled-components';

export const View = styled.div`
  width: 100vw;
`;

export const ViewContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;

  h1:first-of-type {
    margin-top: 60px;
  }

  h1 {
    text-align: center;
  }

  button {
    align-self: center;
    margin-top: 20px;
  }
`;

export const ViewInput = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  input {
    text-align: center;
  }
  div {
    width: 50px;
  }

  @media only screen and (max-width: 360px) {
    div {
      width: 45px;
    }
  }
`;

export const ViewLoading = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  padding: 10px;

  p {
    margin-left: 10px;
  }
`;

export const ViewError = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
`;

export const ErrorConnectContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.white};

  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;

  display: flex;
  align-items: center;
  justify-content: center;
`;
