import React, { HTMLAttributes } from 'react';

import StepItem from '../../molecules/StepItem';
import { Container } from './styles';

type StepListProps = HTMLAttributes<HTMLUListElement> & {
  data: string[];
};

export default function StepList({ data, ...rest }: StepListProps): JSX.Element {
  return (
    <Container {...rest}>
      {data.map((item, index) => (
        <StepItem data-testid="stepItemList" key={String(index)} number={index + 1}>
          {item}
        </StepItem>
      ))}
    </Container>
  );
}
