import { Dispatch, SetStateAction, useEffect, useState } from 'react';

export default function useDebounceState<T>(
  initialState: T,
  delay = 500,
): [T, Dispatch<SetStateAction<T>>, T] {
  const [state, setState] = useState<T>(initialState);
  const [debouncedState, setDebouncedState] = useState<T>(state);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedState(state);
    }, delay);
    return () => clearTimeout(handler);
  }, [state, delay]);

  return [state, setState, debouncedState];
}
