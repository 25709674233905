import styled, { css, keyframes } from 'styled-components';

type ContainerProps = {
  width?: number;
  height?: number;
};

export type GroupButtonsProps = {
  hasMultipleButtons?: boolean;
  children: React.ReactNode;
};

const animationModal = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, -60px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

const animationOverlay = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const Overlay = styled.div`
  animation: 0.3s ${animationOverlay} ease-out;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background-color: rgba(0, 0, 0, 0.5);

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Container = styled.div<ContainerProps>`
  animation: 0.3s ${animationModal} ease-out;

  background-color: ${({ theme: { colors } }) => colors.white};

  width: ${({ width }) => width || 350}px;
  height: ${({ height }) => height || 365}px;

  border-radius: 10px;
  padding: 10px;

  position: relative;
  overflow: hidden;

  @media (max-width: 320px) {
    width: 90%;
  }

  & > button {
    border: 0;
    outline: 0;

    background-color: transparent;

    position: absolute;
    top: 15px;
    right: 20px;

    &:active {
      opacity: 0.7;
    }
  }

  main {
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;

    h1 {
      text-align: center;
    }

    p {
      text-align: center;
    }
  }

  ul {
    list-style: none;

    font-size: 14px;
    font-family: ${props => props.theme.fonts.paragraph.family};
    color: ${props => props.color || props.theme.fonts.paragraph.color};

    overflow-y: scroll;
    height: 120px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

export const GroupButtons = styled.section<GroupButtonsProps>`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: ${({ hasMultipleButtons }) => (hasMultipleButtons ? 'space-around' : 'center')};

  ${({ hasMultipleButtons }) =>
    hasMultipleButtons &&
    css`
      @media (max-width: 320px) {
        button {
          width: 120px;
        }
      }
    `}
`;
