import React, { createContext, ReactNode, useCallback, useState } from 'react';
import BiometryPicturePreview from '../../components/molecules/BiometryPicturePreview';
import PictureTypeProps from '../../models/PictureTypeProps';

export type BiometryPicturePreviewData = PictureTypeProps & {
  base64: string | undefined;
  nextPage: string;
  message: string;
  proposalId: string;
};

export type BiometryPicturePreviewContextData = {
  openBiometryPicturePreview: (data: BiometryPicturePreviewData) => void;
  closeBiometryPicturePreview: () => void;
  isVisible: boolean;
};

type BiometryPicturePreviewProviderProps = {
  children: ReactNode;
};

export const BiometryPicturePreviewContext = createContext<BiometryPicturePreviewContextData>(
  {} as BiometryPicturePreviewContextData,
);

export default function BiometryPicturePreviewProvider({
  children,
}: BiometryPicturePreviewProviderProps): JSX.Element {
  const [visible, setVisible] = useState(false);
  const [biometryPicturePreviewData, setBiometryPicturePreviewData] = useState(
    {} as BiometryPicturePreviewData,
  );

  const openBiometryPicturePreview = ({
    type,
    base64,
    nextPage,
    message,
    proposalId,
  }: BiometryPicturePreviewData) => {
    setBiometryPicturePreviewData({
      type,
      base64,
      nextPage,
      message,
      proposalId,
    });
    setVisible(true);
  };

  const closeBiometryPicturePreview = useCallback(() => setVisible(false), []);

  return (
    <BiometryPicturePreviewContext.Provider
      value={{ openBiometryPicturePreview, closeBiometryPicturePreview, isVisible: visible }}
    >
      {children}

      {visible && (
        <BiometryPicturePreview
          type={biometryPicturePreviewData.type}
          base64={biometryPicturePreviewData.base64}
          nextPage={biometryPicturePreviewData.nextPage}
          message={biometryPicturePreviewData.message}
          proposalId={biometryPicturePreviewData.proposalId}
          close={closeBiometryPicturePreview}
        />
      )}
    </BiometryPicturePreviewContext.Provider>
  );
}
