import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/atoms/Header';
import Title from '../../components/atoms/Title';
import StepList from '../../components/organisms/StepList';
import ButtonFilled from '../../components/atoms/ButtonFilled';
import { Container, Content, StepView, TitleView, Footer } from './styles';
import credCardBack from '../../assets/credit-card-back.png';

const PreparePictureDocumentBack = (): JSX.Element => {
  const navigate = useNavigate();

  const data = [
    'Utilize um documento de identificação com foto (RG, CNH, CTPS e etc.)',
    'Coloque o documento em uma superfície iluminada',
    'Retire o documento do plástico caso você utilize',
    'Certifique-se que o documento está em bom estado de conservação',
    'Posicione o documento ao centro da tela, dentro da linha tracejada',
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container>
      <Header />
      <TitleView>
        <img src={credCardBack} alt="" />
        <Title>Foto do verso do documento</Title>
      </TitleView>
      <Content>
        <Title>Antes de começar atente-se para os pontos abaixo</Title>
        <StepView>
          <StepList data={data} />
        </StepView>

        <Footer>
          <ButtonFilled onClick={() => navigate('/biometry-picture-document-back')}>
            Continuar
          </ButtonFilled>
        </Footer>
      </Content>
    </Container>
  );
};

export default PreparePictureDocumentBack;
