import React from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/atoms/Header';
import Title from '../../components/atoms/Title';
import StepList from '../../components/organisms/StepList';
import ButtonFilled from '../../components/atoms/ButtonFilled';
import { Container, Content, TitleView } from './styles';
import residenceImg from '../../assets/residence-img.png';

const PreparePictureDocumentResidence = (): JSX.Element => {
  const navigate = useNavigate();

  const data = [
    'Utilize um documento de identificação com foto (RG, CNH, CTPS e etc.)',
    'Coloque o documento em uma superfície iluminada',
    'Retire o documento do plástico caso você utilize',
    'Certifique-se que o documento está em bom estado de conservação',
    'Posicione o documento ao centro da tela, dentro da linha tracejada',
  ];

  return (
    <Container>
      <Header />
      <TitleView>
        <img src={residenceImg} alt="" style={{ width: '50%' }} />
        <Title>Foto do comprovante de residencia</Title>
      </TitleView>
      <Content>
        <Title>Antes de começar atente-se para os pontos abaixo</Title>
        <div>
          <StepList data={data} />
        </div>
        <ButtonFilled onClick={() => navigate('/biometry-picture-document-residence')}>
          Continuar
        </ButtonFilled>
      </Content>
    </Container>
  );
};

export default PreparePictureDocumentResidence;
