import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h1 {
    margin: 30px 8px 30px 8px;
    font-weight: 700;
    text-align: center;
    @media (max-width: 335px) {
      margin: 15px 8px 15px 8px;
    }
  }
  button {
    margin-top: 25px;
  }
  div {
    @media (max-width: 335px) {
      margin: 0 15px 0 15px;
    }
  }
`;

export const StepView = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  div {
    @media (max-width: 320px) {
      margin: 0 15px 0 15px;
    }
  }
`;

export const TitleView = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;

  h1 {
    margin-top: 20px;
  }
`;

export const Footer = styled.footer`
  padding: 10px 5px;
  width: 100%;
`;
