import styled from 'styled-components';

type H1Props = {
  fontSize?: number;
  color?: string;
};

export const H1 = styled.h1<H1Props>`
  font-size: ${props => props.fontSize || props.theme.fonts.title.size}px;
  font-family: ${props => props.theme.fonts.title.family};
  font-weight: 500;
  color: ${props => props.color || props.theme.fonts.title.color};
`;
