import React, { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';

import Header from '../../components/atoms/Header';
import ButtonFilled from '../../components/atoms/ButtonFilled';
import Paragraph from '../../components/atoms/Paragraph';
import Title from '../../components/atoms/Title';
import TextEntry from '../../components/atoms/TextEntry';
import NumberEntry from '../../components/atoms/NumberEntry';
import CellphoneEntry from '../../components/atoms/CellphoneEntry';
import CPFEntry from '../../components/atoms/CPFEntry';
import ErrorConnect from '../../components/molecules/ErrorConnect';
import DateEntry from '../../components/atoms/DateEntry';
import { regexCPF, regexDate, regexTelephone } from '../../utils/regex';
import onlyNumbers from '../../utils/onlyNumbers';
import validateCPF from '../../utils/validateCPF';
import useApplicationConfig from '../../hooks/useApplicationConfig';
import useProposal from '../../hooks/useProposal';
import useDialog from '../../hooks/useDialog';
import { dateStringToISOFormat } from '../../utils/dateFunctions';
import SendDataToPreValidationService from '../../services/SendDataToPreValidationService';
import errorHandling from '../../utils/errorHandling';
import {
  Container,
  Content,
  Form,
  CellphoneSection,
  Footer,
  ErrorConnectContainer,
} from './styles';

export type ProposalOneFLowPersonalDataFormData = {
  peopleName: string;
  peopleBirthday: string;
  peopleEmail: string;
  peopleDocument: string;
  peopleTelephoneArea: string;
  peopleTelephoneNumber: string;
  userRegistry?: string;
};

const schema = yup.object({
  peopleName: yup.string().required('Obrigatório'),
  peopleBirthday: yup.string().required('Obrigatório').matches(regexDate, {
    message: 'Data inválida',
    excludeEmptyString: true,
  }),
  peopleEmail: yup.string().required('Obrigatório').email('Email inválido'),
  peopleDocument: yup.string().required('Obrigatório').matches(regexCPF, {
    message: 'CPF inválido',
    excludeEmptyString: true,
  }),
  peopleTelephoneArea: yup
    .string()
    .required('Obrigatório')
    .max(2, 'DDD inválido')
    .min(2, 'DDD inválido'),
  peopleTelephoneNumber: yup.string().required('Obrigatório').matches(regexTelephone, {
    message: 'Telefone inválido',
    excludeEmptyString: true,
  }),
});

export default function ProposalOneFLowPersonalData(): JSX.Element {
  const [loading, setLoading] = useState(false);
  const [errorConnect, setErrorConnect] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const preValidationDataRef = useRef({ data: {} as ProposalOneFLowPersonalDataFormData });

  const {
    applicationConfig: {
      politic_and_terms_url,
      commercial_origin_id,
      commercial_group_id,
      commercial_origin,
      promoter_id,
      product_id,
    },
  } = useApplicationConfig();
  const { setProposal } = useProposal();
  const { openDialog } = useDialog();

  const { handleSubmit, control, setError } = useForm({
    resolver: yupResolver(schema),
  });
  const navigate = useNavigate();

  async function sendProposal() {
    try {
      setLoading(true);
      setButtonDisabled(true);
      const {
        id,
        people_birthday,
        people_document,
        people_email,
        people_name,
        people_telephone_area,
        people_telephone_number,
        user_registry,
      } = await SendDataToPreValidationService.execute({
        ...preValidationDataRef.current.data,
        commercialGroupId: commercial_group_id,
        commercialOrigin: commercial_origin,
        commercialOriginId: commercial_origin_id,
        promoterId: promoter_id,
        productId: product_id,
      });

      setProposal({
        id,
        people_birthday,
        people_document,
        people_email,
        people_name,
        people_telephone_area,
        people_telephone_number,
        user_registry,
      });

      navigate('/proposal-one-flow-sms-validation');
    } catch (error: any) {
      const { type, message } = errorHandling(error);

      if (type === 'ErrorConnect') {
        setErrorConnect(true);
        return;
      }

      setErrorConnect(false);
      openDialog({
        title: 'Ocorreu um erro no envio das informações',
        message,
        type: 'danger',
      });
    } finally {
      setLoading(false);
      setButtonDisabled(false);
    }
  }

  const onSubmit = (data: ProposalOneFLowPersonalDataFormData) => {
    const preparedData: ProposalOneFLowPersonalDataFormData = {
      ...data,
      peopleDocument: onlyNumbers(data.peopleDocument),
      peopleTelephoneNumber: onlyNumbers(data.peopleTelephoneNumber),
      peopleBirthday: dateStringToISOFormat(data.peopleBirthday),
    };

    if (!validateCPF(preparedData.peopleDocument)) {
      setError('peopleDocument', {
        type: 'validate',
        message: 'CPF inválido',
      });

      return;
    }

    preValidationDataRef.current.data = preparedData;
    sendProposal();
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    return () => {
      setLoading(false);
      setButtonDisabled(false);
    };
  }, []);

  return (
    <Container>
      <Header />

      {!errorConnect ? (
        <>
          <Content>
            <Title>Vamos começar! Preencha os dados abaixo.</Title>

            <Form>
              <Controller
                control={control}
                name="peopleName"
                defaultValue=""
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextEntry
                    error={error?.message}
                    placeholder="Nome"
                    value={value}
                    onChange={onChange}
                  />
                )}
              />

              <Controller
                control={control}
                name="peopleBirthday"
                defaultValue=""
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <DateEntry
                    error={error?.message}
                    placeholder="Data de nascimento"
                    value={value}
                    onChange={onChange}
                  />
                )}
              />

              <Controller
                control={control}
                name="peopleEmail"
                defaultValue=""
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextEntry
                    error={error?.message}
                    placeholder="Email"
                    value={value}
                    onChange={onChange}
                  />
                )}
              />

              <Controller
                control={control}
                name="peopleDocument"
                defaultValue=""
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <CPFEntry error={error?.message} value={value} onChange={onChange} />
                )}
              />

              <CellphoneSection>
                <Controller
                  control={control}
                  name="peopleTelephoneArea"
                  defaultValue=""
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <NumberEntry
                      error={{
                        state: !!error?.message,
                        message: error?.message,
                      }}
                      width={80}
                      placeholder="DDD"
                      maxLength={2}
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="peopleTelephoneNumber"
                  defaultValue=""
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <CellphoneEntry error={error?.message} value={value} onChange={onChange} />
                  )}
                />
              </CellphoneSection>

              <Controller
                name="userRegistry"
                defaultValue=""
                control={control}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <NumberEntry
                    error={{
                      state: !!error?.message,
                      message: error?.message,
                    }}
                    value={value}
                    placeholder="Código de indicação (opcional)"
                    onChange={onChange}
                  />
                )}
              />
            </Form>
          </Content>

          <Footer>
            <Paragraph>
              Ao continuar, declaro que li e estou de acordo com os
              <a href={politic_and_terms_url} target="_blank" rel="noreferrer">
                {' '}
                Termos de uso e Política de privacidade
              </a>
            </Paragraph>
            <ButtonFilled
              loading={loading}
              disabled={buttonDisabled}
              onClick={handleSubmit<ProposalOneFLowPersonalDataFormData>(onSubmit)}
            >
              Aceitar condições e continuar
            </ButtonFilled>
          </Footer>
        </>
      ) : (
        <ErrorConnectContainer>
          <ErrorConnect onClick={() => sendProposal()} loading={loading} />
        </ErrorConnectContainer>
      )}
    </Container>
  );
}
