import React, { InputHTMLAttributes, useState } from 'react';
import { cpfMask } from '../../../utils/masks';
import { Container, Content } from './styles';

type CPFEntryProps = InputHTMLAttributes<HTMLInputElement> & {
  onChange: (text: string) => void;
  error?: string;
};

const CPFEntry = ({ onChange, error, ...rest }: CPFEntryProps): JSX.Element => {
  const [isFocused, setIsFocused] = useState(false);
  return (
    <Container>
      <Content data-testid="CPFEntryContent" isErrored={!!error} isFocused={isFocused}>
        <input
          data-testid="CPFEntry"
          type="text"
          placeholder="CPF"
          onChange={e => {
            onChange(cpfMask(e.target.value));
          }}
          maxLength={14}
          inputMode="numeric"
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          {...rest}
        />
      </Content>
      {error && <span>{error}</span>}
    </Container>
  );
};

export default CPFEntry;
