import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/atoms/Header';
import Title from '../../components/atoms/Title';
import StepList from '../../components/organisms/StepList';
import ButtonFilled from '../../components/atoms/ButtonFilled';
import { Container, Content, TitleView, Footer } from './styles';
import PictureSelfie from '../../assets/picture-selfie.png';

const PreparePictureSelfie = (): JSX.Element => {
  const navigate = useNavigate();
  const data = [
    'Retire boné, óculos ou qualquer ítem que cubra o seu rosto',
    'Posicione o seu rosto no centro da tela dentro da linha tracejada',
    'Vá para um lugar com boa iluminação',
    'Ao continuar sua foto de rosto será utilizada',
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container>
      <Header />
      <TitleView>
        <img src={PictureSelfie} alt="" height="100px" />
        <Title>Foto do rosto</Title>
      </TitleView>
      <Content>
        <Title>Antes de começar atente-se para os pontos abaixo</Title>
        <StepList data={data} />
        <Footer>
          <ButtonFilled onClick={() => navigate('/biometry-picture-face')}>Continuar</ButtonFilled>
        </Footer>
      </Content>
    </Container>
  );
};

export default PreparePictureSelfie;
