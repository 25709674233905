import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;
  h1 {
    padding: 10px 20px 0px 20px;
  }
`;

export const Content = styled.div`
  padding: 10px 20px 0px 20px;
  margin-top: 15px;
  div {
    margin-bottom: 15px;
  }
`;

export const ViewButtons = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30%;
`;

export const Form = styled.form`
  margin-top: 20px;
`;
