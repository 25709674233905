import api from '../../http/api';
import PictureTypeProps from '../../models/PictureTypeProps';

type SaveImageProps = PictureTypeProps & {
  base64: string | undefined;
  insuranceId?: string;
  proposalId: string;
};

type SaveImageResponse = {
  message: string;
};

export default class SavePictureService {
  public static async execute({
    type,
    base64,
    insuranceId,
    proposalId,
  }: SaveImageProps): Promise<SaveImageResponse> {
    const { data } = await api.post('self-attendance/save-type-picture', {
      type,
      base64,
      insurance_id: insuranceId,
      proposal_id: proposalId,
    });
    return data;
  }
}
