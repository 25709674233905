import styled from 'styled-components';

type TextProps = {
  fontSize?: number;
  color?: string;
};

export const Text = styled.p<TextProps>`
  font-size: ${props => props.fontSize || props.theme.fonts.paragraph.size}px;
  font-family: ${props => props.theme.fonts.paragraph.family};
  color: ${props => props.color || props.theme.fonts.paragraph.color};
`;
