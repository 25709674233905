import styled, { css } from 'styled-components';

type ButtonCustom = {
  width?: number;
  height?: number;
};

export const ButtonCustom = styled.button<ButtonCustom>`
  background-color: transparent;
  border: none;
  outline: 0;

  width: ${props => (props.width ? `${props.width}px` : '100%')};
  height: ${props => props.height || 20}px;

  transition: opacity 0.2s ease-out;

  font-size: 14px;
  font-family: ${props => props.theme.fonts.paragraph.family};
  font-weight: 500;
  color: ${props => props.theme.colors.primary};

  ${props =>
    props.disabled &&
    css`
      opacity: 0.5;
    `}

  &:active {
    opacity: 0.7;
  }
`;
