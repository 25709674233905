import React, { HTMLAttributes } from 'react';
import { useNavigate } from 'react-router-dom';
import Icon from '../Icon';
import { Container } from './style';

const Header = ({ ...rest }: HTMLAttributes<HTMLDivElement>): JSX.Element => {
  const navigate = useNavigate();

  return (
    <Container {...rest}>
      <Icon
        data-testid="iconBackNavigate"
        onClick={() => navigate(-1)}
        name="FiChevronLeft"
        size={40}
        strokeWidth={1.2}
      />
    </Container>
  );
};

export default Header;
