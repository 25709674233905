import styled from 'styled-components';

type ContainerProps = {
  backgroundColor?: string;
};
export const Container = styled.div<ContainerProps>`
  width: 100vw;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;

  position: fixed;
  bottom: 0;
  top: 0;

  z-index: 50;

  background-color: ${props => props.backgroundColor || 'transparent'};

  svg {
    font-size: 100px;
    color: #c7c7c7;
  }
`;
