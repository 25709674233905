import React from 'react';
import ReactDOM from 'react-dom';

type PortalProps = {
  children: React.ReactNode;
};

const existingPortal = document.getElementById('portal');
let portalCreated: HTMLDivElement;

if (!existingPortal) {
  portalCreated = document.createElement('div');
  portalCreated.setAttribute('id', 'portal');
  document.body.appendChild(portalCreated);
}

export default function Portal({ children }: PortalProps): JSX.Element {
  return ReactDOM.createPortal(children, existingPortal || portalCreated);
}
