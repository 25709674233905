import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/atoms/Header';
import Title from '../../components/atoms/Title';
import ZipCodeEntry from '../../components/atoms/ZipCodeEntry';
import TextEntry from '../../components/atoms/TextEntry';
import ButtonFilled from '../../components/atoms/ButtonFilled';
import useProposal from '../../hooks/useProposal';
import onlyNumbers from '../../utils/onlyNumbers';
import ZipCodeService from '../../services/ZipCodeServices';
import { regexZipCode } from '../../utils/regex';
import { Container, Content, ViewButtons, Form } from './styles';

export type ProposalTwoFlowAddress = {
  addressZipcode: string;
  addressStreet: string;
  addressNumber: string;
  addressDistrict: string;
  addressState: string;
  addressCity: string;
  addressReference: string;
};

const schema = yup.object({
  addressZipcode: yup.string().required('Obrigatório').matches(regexZipCode, {
    message: 'CEP inválido',
    excludeEmptyString: true,
  }),
  addressStreet: yup.string().required('Obrigatório'),
  addressNumber: yup.string().required('Obrigatório'),
  addressDistrict: yup.string().required('Obrigatório'),
  addressState: yup.string().required('Obrigatório'),
  addressCity: yup.string().required('Obrigatório'),
  addressReference: yup.string(),
});

const PersonalTwoFlowAddress = (): JSX.Element => {
  const [loadingZipCode, setLoadingZipCode] = useState(false);
  const navigate = useNavigate();

  const { proposal, setProposal } = useProposal();

  const { handleSubmit, control, setValue, clearErrors } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: ProposalTwoFlowAddress) => {
    const {
      addressZipcode,
      addressStreet,
      addressState,
      addressNumber,
      addressCity,
      addressDistrict,
      addressReference,
    } = data;
    setProposal({
      ...proposal,
      address_zipcode: Number(onlyNumbers(addressZipcode)),
      address_street: addressStreet,
      address_state: addressState,
      address_number: addressNumber,
      address_city: addressCity,
      address_district: addressDistrict,
      address_reference: addressReference,
    });
    navigate('/proposal-two-flow-professional-data');
  };

  async function searchZipCode(zipCode: string) {
    const parsedZipCode = onlyNumbers(zipCode);

    try {
      setLoadingZipCode(true);
      const { street, neighborhood, state, city } = await ZipCodeService.findZipCodeByNumber(
        parsedZipCode,
      );
      clearErrors();
      setValue('addressStreet', street);
      setValue('addressDistrict', neighborhood);
      setValue('addressState', state);
      setValue('addressCity', city);
    } finally {
      setLoadingZipCode(false);
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container>
      <Header />
      <Title>Informe seus dados de endereço</Title>
      <Content>
        <div>
          <Form>
            <Controller
              control={control}
              name="addressZipcode"
              defaultValue=""
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <ZipCodeEntry
                  error={error?.message}
                  loading={loadingZipCode}
                  value={value}
                  onChange={e => {
                    onChange(e);
                    if (e.toString().length === 9) {
                      searchZipCode(e as string);
                    }
                  }}
                />
              )}
            />

            <Controller
              control={control}
              name="addressStreet"
              defaultValue=""
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextEntry
                  error={error?.message}
                  value={value}
                  placeholder="Rua"
                  onChange={onChange}
                />
              )}
            />

            <Controller
              control={control}
              name="addressNumber"
              defaultValue=""
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextEntry
                  error={error?.message}
                  value={value}
                  placeholder="Nº"
                  onChange={onChange}
                />
              )}
            />

            <Controller
              control={control}
              name="addressDistrict"
              defaultValue=""
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextEntry
                  error={error?.message}
                  value={value}
                  placeholder="Bairro"
                  onChange={onChange}
                />
              )}
            />

            <Controller
              control={control}
              name="addressState"
              defaultValue=""
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextEntry
                  error={error?.message}
                  value={value}
                  placeholder="Estado"
                  onChange={onChange}
                />
              )}
            />

            <Controller
              control={control}
              name="addressCity"
              defaultValue=""
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextEntry
                  error={error?.message}
                  value={value}
                  placeholder="Cidade"
                  onChange={onChange}
                />
              )}
            />

            <Controller
              control={control}
              name="addressReference"
              defaultValue=""
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextEntry
                  error={error?.message}
                  value={value}
                  placeholder="Complemento"
                  onChange={onChange}
                />
              )}
            />
          </Form>

          <ViewButtons>
            <ButtonFilled onClick={handleSubmit<ProposalTwoFlowAddress>(onSubmit)}>
              Avançar
            </ButtonFilled>
          </ViewButtons>
        </div>
      </Content>
    </Container>
  );
};

export default PersonalTwoFlowAddress;
