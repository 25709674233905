import styled, { css } from 'styled-components';

type ContentProps = {
  isErrored: boolean;
};

export const Container = styled.div`
  span {
    margin-left: 5px;
    font-size: 14px;
    font-family: ${props => props.theme.fonts.paragraph.family};
    color: ${$props => $props.theme.colors.danger};
  }
`;

export const Content = styled.div<ContentProps>`
  display: flex;
  align-items: center;

  width: 100%;
  height: 65px;

  border-radius: 10px;
  padding: 5px 10px;

  ${({ isErrored, theme }) =>
    isErrored &&
    css`
      border: 1.5px solid ${theme.colors.danger};
    `};

  background-color: ${({ theme: { colors } }) => colors.white100};

  svg {
    color: ${({ theme: { colors } }) => colors.gray300};
    font-size: 18px;
  }

  select {
    width: 100%;
    height: 95%;

    border: 0;
    outline: none;
    background-color: transparent;

    font-size: 18px;
    font-family: ${({ theme: { fonts } }) => fonts.paragraph.family};
    color: ${({ theme: { colors } }) => colors.gray400};
  }
`;
