import React, { HTMLAttributes } from 'react';
import { useTheme } from 'styled-components';
import Paragraph from '../../atoms/Paragraph';
import { Item } from './styles';
import IconWithRoundBackground from '../IconWithRoundBackground';

type InsuranceItemProps = HTMLAttributes<HTMLDivElement> & {
  height?: number;
  name: string;
  isHired?: boolean;
  onClick: () => void;
};

const InsuranceItem = ({
  height,
  name,
  isHired,
  onClick,
  ...rest
}: InsuranceItemProps): JSX.Element => {
  const { colors } = useTheme();
  return (
    <Item height={height} onClick={onClick} {...rest}>
      <section>
        <Paragraph data-testid="Paragraph">{name}</Paragraph>
      </section>
      <section>
        {isHired && (
          <IconWithRoundBackground
            backgroundColor={colors.success}
            width={30}
            heigth={30}
            icon={{ name: 'FiCheck', color: colors.white, size: 22 }}
          />
        )}
      </section>
    </Item>
  );
};

export default InsuranceItem;
