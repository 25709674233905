import React, { HTMLAttributes, ReactNode } from 'react';

import { Container } from './styles';

type FixedToBottomProps = HTMLAttributes<HTMLDivElement> & {
  children: ReactNode;
};

export default function FixedToBottom({ children, ...rest }: FixedToBottomProps): JSX.Element {
  return <Container {...rest}>{children}</Container>;
}
