import React from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/atoms/Header';
import Title from '../../components/atoms/Title';
import StepList from '../../components/organisms/StepList';
import ButtonFilled from '../../components/atoms/ButtonFilled';
import { Container, Content, TitleView } from './styles';
import creditCardFront from '../../assets/credit-card-front.png';

const PreparePictureDocument = (): JSX.Element => {
  const navigate = useNavigate();

  const data = [
    'Utilize um documento de identificação com foto (RG, CNH, CTPS e etc.)',
    'Coloque o documento em uma superfície iluminada',
    'Retire o documento do plástico caso você utilize',
    'Certifique-se que o documento está em bom estado de conservação',
    'Posicione o documento ao centro da tela, dentro da linha tracejada',
  ];

  return (
    <Container>
      <Header />
      <TitleView>
        <img src={creditCardFront} alt="" />
        <Title>Foto da frente do documento</Title>
      </TitleView>
      <Content>
        <Title>Antes de começar atente-se para os pontos abaixo</Title>
        <div>
          <StepList data={data} />
        </div>
        <ButtonFilled onClick={() => navigate('/biometry-picture-document-front')}>
          Continuar
        </ButtonFilled>
      </Content>
    </Container>
  );
};

export default PreparePictureDocument;
