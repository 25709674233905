export default {
  title: 'red',

  colors: {
    black: '#000000',
    white: '#FFFFFF',
    white100: '#F2F2F2',
    white200: '#EBEBEB',
    gray300: '#828282',
    gray200: '#A5A5A5',
    gray400: '#43444A',
    gray100: '#A5A0B4',
    gray50: '#CBCACA',
    primary: '#E73A5C',
    danger: '#E73A5C',
    success: '#57E3C2',
    warning: '#F2994A',
  },

  fonts: {
    title: {
      family: 'Roboto',
      size: 22,
      color: '#828282',
    },

    paragraph: {
      family: 'Roboto',
      size: 16,
      color: '#828282',
    },
  },
};
