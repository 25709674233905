import styled, { css } from 'styled-components';

type ContainerProps = {
  isFocused: boolean;
  height?: number;
};

export const Container = styled.div<ContainerProps>`
  display: flex;
  width: 100%;
  height: ${props => props.height || 65}px;
  align-items: center;
  padding-right: 10px;
  border-radius: 10px;
  background-color: ${props => props.theme.colors.white100};

  ${({ isFocused, theme }) =>
    isFocused &&
    css`
      border: 1.5px solid ${theme.colors.primary};
    `};

  input {
    width: 90%;
    height: 95%;
    border: 0;
    padding: 10px;
    font-size: 18px;
    outline: none;
    background-color: transparent;
    font-family: ${props => props.theme.fonts.paragraph.family};
    color: ${props => props.theme.colors.gray400};
  }

  input::placeholder {
    color: ${({ theme: { colors } }) => colors.gray200};
  }
`;

export const IconButton = styled.button`
  border: 0;
  background-color: transparent;
`;
